import React from 'react';

import { Breadcrumb } from 'antd';

import ArchivesForm from './archives-form';
import Header from '../../components/header';
import Sider from '../../components/sider';

import './add.css'
import storage from '../../model/storage'

function EditMember(props) {
  storage.set('selectedKeys', 'archivesList')

  const { memberId, member } = props.location.state

  return (
    <div className="wrap">
      <Header />
      <Sider />
      <div className="container">
        <div className="main-content">
          <div className="main-content">
            <div className="breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>首页</Breadcrumb.Item>
                <Breadcrumb.Item>档案管理</Breadcrumb.Item>
                <Breadcrumb.Item>编辑档案</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="content">
              <ArchivesForm idcard={0} member_id={memberId} member={member} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditMember