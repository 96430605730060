import React, { useEffect } from 'react';

import { Button, Breadcrumb } from 'antd';
import { useHistory } from "react-router-dom";
import axios from "axios"
import storage from '../../model/storage'
import global from '../../model/config'

import Header from '../../components/header';
import Sider from '../../components/sider';

const MultEdit = (props) => {
    let history = useHistory()
    let baseUrl = global.baseUrl;
    let user = storage.get('user')

    const returnHandler = () => {
        history.goBack()
    }
    useEffect(() => {
        let areaId = 20;
        axios.post(baseUrl + 'index/member/index', {
            token: user.token,
            area_id: areaId
        })
        axios.post(baseUrl + 'v1/member/index', {
            token: user.token,
            area_id: areaId
        })
    })
    return (
        <div className="wrap">
            <Header />
            <Sider />
            <div className="container">
                <div className="main-content">
                    <Breadcrumb>
                        <Breadcrumb.Item>首页</Breadcrumb.Item>
                        <Breadcrumb.Item>档案管理</Breadcrumb.Item>
                        <Breadcrumb.Item>档案详情</Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="member-info" id="member-info">
                        <div className='btn-area'>
                            <Button htmlType="button" size={'large'} style={{ marginLeft: '15px' }} onClick={returnHandler}>返回</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MultEdit