import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { Button, Breadcrumb, Table, Modal, Space, notification, Input, Checkbox, Form } from 'antd';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import Header from '../../components/header';
import Sider from '../../components/sider';
import global from '../../model/config'

import axios from "axios"

import storage from '../../model/storage'
import './list.css'

const DepartmentList = () => {
  const columns = [
    // {
    //   title: '',
    //   dataIndex: 'id',
    //   key: 'id',
    //   render: (record)=>{
    //     return(<Checkbox onChange={()=>{console.log(record)}}></Checkbox>)
    //   }
    // },
    {
      title: '机构名称',
      dataIndex: 'org_name',
      key: 'org_name11',
      width: '20%',
    },
    {
      title: '成员人数',
      dataIndex: 'member',
      key: 'member',
      width: '20%',
    },
    {
      title: '更新时间',
      dataIndex: 'gmt_modified',
      key: 'gmt_modified',
      width: '25%',
    },
    {
      title: '',
      width: '35%',
      key: 'action',
      render: (record) => (
        <Space size="middle">
          <Button type="link" icon={<EditOutlined />} onClick={() => editHandler(record.id)}>编辑</Button>
          <Button type="link" danger icon={<DeleteOutlined />} onClick={() => deleteHandler(record.id)}>删除</Button>
        </Space>
      )
    },
  ];
  const organize = JSON.parse(sessionStorage.getItem('organize'))
  console.log(organize)
  const [pagination, setPagination] = useState(organize || { current: 1, pageSize: 10 });
  const [loading, setLoading] = useState(false);
  const [orgList, setOrgData] = useState([]);
  const [orgName, setOrgName] = useState(null);
  const [selected, setSelected] = useState([]);

  const { Search } = Input;

  storage.set('selectedKeys', 'deptList')

  let history = useHistory();

  const editHandler = orgId => {
    history.push({
      pathname: '/edit_department',
      state: {
        orgId: orgId
      },
    })
  }

  const handleTableChange = (pagination) => {
    fetch(pagination);
  };

  let baseUrl = global.baseUrl;
  let user = storage.get('user')
  const { confirm } = Modal;

  const deleteHandler = orgId => {
    confirm({
      title: '确定要删除此机构?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      centered: true,
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        axios.post(baseUrl + 'index/organization/delete', {
          org_id: orgId,
          token: user.token
        })
          .then(function (response) {
            if (response.data.code === 20000) {
              notification['success']({
                message: '成功',
                description: '数据删除成功',
              });

              fetch(pagination);
            } else {
              notification['error']({
                message: '失败',
                description: response.data.datas.msg,
              });
            }
          })
      },
      onCancel() {

      },
    });
  }

  const fetch = async (params = {}) => {
    setLoading(true)
    const res = await axios.post(baseUrl + 'index/organization/index', {
      curr_page: params.current,
      limit: params.pageSize,
      token: user.token,
      org_name: orgName
    })
    if (res.data.code === 20000) {
      setOrgData(res.data.datas.list);
      setPagination({
        ...params,
        total: res.data.datas.total,
      });
      sessionStorage.setItem('organize', JSON.stringify(params))
    } else {
      notification['error']({
        message: '失败',
        description: res.data.datas.msg,
      });
    }
    setLoading(false)
  };

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      fetch(pagination)
    }
    return () => isSubscribed = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgName]);

  const onSearch = (value) => {
    setOrgName(value)
    setPagination({ current: 1, pageSize: 10 })
  }
  const delMult = () => {
    confirm({
      title: '确定要删除此机构?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      centered: true,
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        axios.post(baseUrl + 'index/organization/delMult', {
          ids: selected,
          token: user.token
        })
          .then(function (response) {
            if (response.data.code === 20000) {
              notification['success']({
                message: '成功',
                description: '数据删除成功',
              });

              fetch(pagination);
            } else {
              notification['error']({
                message: '失败',
                description: response.data.datas.msg,
              });
            }
          })
      },
      onCancel() {

      },
    });
  }
  return (
    <div className="wrap">
      <Header />
      <Sider />
      <div className="container">
        <div className="main-content">
          <Breadcrumb>
            <Breadcrumb.Item>首页</Breadcrumb.Item>
            <Breadcrumb.Item>机构管理</Breadcrumb.Item>
            <Breadcrumb.Item>机构列表</Breadcrumb.Item>
          </Breadcrumb>

          <div className="department-list">
            {/* <div className="search-area">
            <Search placeholder="机构名称" allowClear enterButton="查询" size="large" onSearch={onSearch} />
            <Button type="primary" onClick={delMult}>批量删除</Button>
            </div> */}
            <Form
              layout="inline"
              style={{ marginBottom: 15 }}
            >
              <Form.Item>
                <Search placeholder="机构名称" allowClear enterButton="查询" size="large" onSearch={onSearch} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" size="large" onClick={delMult}>批量删除</Button>
              </Form.Item>
            </Form>
            <Table
              columns={columns}
              dataSource={orgList}
              pagination={{ ...pagination, defaultCurrent: pagination.current }}
              loading={loading}
              onChange={handleTableChange}
              rowSelection={{
                onChange: (selectedRowKeys) => {
                  setSelected(selectedRowKeys);
                },
                selection: true,
                getCheckboxProps: (record) => ({
                  disabled: record.member != 0
                })
              }}
              rowKey={orgList => orgList.id} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DepartmentList