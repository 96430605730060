import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import storage from '../model/storage'

const PrivateRoute = (props) => {
  let user = storage.get('user')
  let isLogged = false;
  if(!user){
    isLogged = false;
  }else{
    isLogged = user.isLogged;
  }
  
  return (
    isLogged ? (
      <Route exact={props.exact} path={props.path} component={props.component} />
    ) : (
      <Redirect to={{ pathname: "/signin" }} />
    )
  )
}

export default PrivateRoute