// const global = {
//     baseUrl: '/',
//     mapUrl: 'http://www.jmk.com/',
//     photoUrl: 'http://www.jmk.com/',
//     uploadUrl: 'http://www.jmk.com/index/upload/image'
// }

const global = {
    baseUrl: '/',
    mapUrl: '/',
    uploadUrl: '/index/upload/image'
}

export default global