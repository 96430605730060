import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Card, List, Spin, Modal } from 'antd';
import { useHistory } from "react-router-dom";

import './index.css'

import Header from '../../components/header';
import Sider from '../../components/sider';
import storage from '../../model/storage'
import axios from "axios"
import global from '../../model/config'

import { Chart } from '@antv/g2';
//import { Pie, Bar } from '@ant-design/charts';

import { Link } from "react-router-dom";

function Home() {
  storage.set('selectedKeys', 'home')

  let baseUrl = global.baseUrl;
  let history = useHistory();

  var configAge = {
    height: 350,
    xField: 'value',
    yField: 'age',
    seriesField: 'age',
  };

  var configEducation = {
    appendPadding: 10,
    angleField: 'value',
    colorField: 'name',
    height: 350,
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: function formatter(v) {
          return v;
        },
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: { textAlign: 'center' },
      autoRotate: false,
      content: '{value}',
    },
    interactions: [
      { type: 'element-selected' },
      { type: 'element-active' },
      { type: 'pie-statistic-active' },
    ],
  };

  let user = storage.get('user')
  // const [dataSex, setSexData] = useState([])
  // const [dataEducation, setEducatioData] = useState([])
  // const [dataAge, setAgeData] = useState([])
  const [baseInfo, setBaseInfo] = useState();
  const [noticeList, setNoticeList] = useState([])
  const [loading, setLoadingState] = useState(false);

  var configSex = {
    appendPadding: 10,
    angleField: 'value',
    colorField: 'name',
    radius: 0.9,
    height: 350,
    label: {
      type: 'inner',
      offset: '-30%',
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [{ type: 'element-active' }],
  };

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      setLoadingState(true)
      getInfo().then(res => {
        if (res.data.code === 20000) {
          let item = res.data.datas.list
          Pie('sex', item.sex_info)
          Pie('edu', item.education_info)
          Bar(item.age_info)
          // setSexData(item.sex_info)
          // setEducatioData(item.education_info)
          // setAgeData(item.age_info)
          setNoticeList(item.notice_list)
          setBaseInfo(item.base_info)
        } else {
          Modal.warning({
            title: '错误信息',
            centered: true,
            content: res.data.datas.msg,
            onOk: () => {
              Modal.destroyAll()
              console.log('shitory push?')
              history.push({
                pathname: '/signin'
              })
            }
          })
        }
        setLoadingState(false)
      });
    }
    return () => isSubscribed = false;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInfo = async () => {
    const result = await axios.post(baseUrl + 'index/index/get_home_info', {
      token: user.token,
    })
    return result
  }
  const Bar = (data) => {
    let bar = new Chart({
      container: 'age', // 指定图表容器 ID
      width: 350,
      height: 350, // 指定图表高度
    });
    bar.data(data);
    bar.scale('value', {
      alias: '人数',
    });

    bar.interval()
      .color('age')
      .position('age*value');
    bar.render();
  }
  const Pie = (id, data) => {
    let chart = new Chart({
      container: id,
      autoFit: true,
      height: 350,
    });

    chart.coordinate('theta', {
      radius: 0.75,
      innerRadius: 0.5,
    });
    chart.data(data);
    chart.interval()
      .adjust('stack')
      .position('value')
      .color('name')
      .state({
        active: {
          style: element => {
            const shape = element.shape;
            return {
              lineWidth: 10,
              stroke: shape.attr('fill'),
              strokeOpacity: shape.attr('fillOpacity'),
            };
          },
        },
      });

    chart.legend('name', {
      position: 'right'
    });
    chart.render();
  }
  const getIntro = (obj) => {
    //if(!!obj) console.log('intro' in obj)
    if (!!obj && 'intro' in obj) return obj.intro
  }
  return (
    <div className="home-wrap">
      <Header />
      <Sider />
      <div className="container">
        <Spin tip="Loading..." spinning={loading} size="large" delay={500}>
          <div className="main-content" style={{ padding: '15px' }}>
            <Row style={{ marginBottom: '15px' }}>
              <Col className="gutter-row" span={24} >
                <Card
                  title="部门简介"
                  headStyle={{
                    borderLeft: '3px solid #1677ff',
                    paddingLeft: '10px'
                  }}
                  extra={
                    <Button
                      type="link"
                      onClick={() => {
                        history.push({
                          pathname: '/intro',
                          params: baseInfo
                        })
                      }}
                    >修改</Button>
                  }
                >
                  <p dangerouslySetInnerHTML={{ __html: getIntro(baseInfo) }}></p>
                </Card>
              </Col>
            </Row>
            <Row gutter={16} >
              <Col className="gutter-row" span={8} >
                <Card
                  title="人数与男女比例"
                  headStyle={{
                    borderLeft: '3px solid #1677ff',
                    paddingLeft: '10px'
                  }}>
                  <div id="sex"></div>
                  {/* <Pie {...configSex} data={dataSex}/> */}
                </Card>
              </Col>
              <Col className="gutter-row" span={8}>
                <Card
                  title="年龄分布统计"
                  headStyle={{
                    borderLeft: '3px solid #1677ff',
                    paddingLeft: '10px'
                  }}>
                  <div id="age"></div>
                  {/* <Bar {...configAge} data={dataAge} /> */}
                </Card>
              </Col>
              <Col className="gutter-row" span={8}>
                <Card
                  title="文化程度统计"
                  headStyle={{
                    borderLeft: '3px solid #1677ff',
                    paddingLeft: '10px'
                  }}>
                  <div id="edu"></div>
                  {/* <Pie {...configEducation} data={dataEducation} /> */}
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <Col className="gutter-row" span={24} >
                <Card
                  title="最新通告"
                  headStyle={{
                    borderLeft: '3px solid #1677ff',
                    paddingLeft: '10px'
                  }}>
                  <List
                    dataSource={noticeList}
                    renderItem={item => (
                      <List.Item key={item.id}>
                        <Link
                          to={{
                            pathname: "/notice_content",
                            state: { noticeId: item.id }
                          }}
                        >{item.title}
                        </Link>

                      </List.Item>
                    )}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </Spin>
      </div>
    </div>
  )
}

export default Home