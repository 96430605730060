import React, { useState, useEffect } from 'react';

import { Button, Form, Input, Breadcrumb, Row, Col, Modal, Spin } from 'antd';

import Header from '../../components/header';
import Sider from '../../components/sider';

import global from '../../model/config'

import axios from "axios"

import './add.css'
import storage from '../../model/storage'

const AddDepartment = () => {
  storage.set('selectedKeys', 'addDept')

  const [form] = Form.useForm();

  /*const getCoordinate = (coordinate = null) => {
    form.setFieldsValue({
      coordinateTxt: coordinate
    })
  }*/

  let baseUrl = global.baseUrl;
  let user = storage.get('user')
  const [loading, setLoadingState] = useState(false);
  const [position, setPosition] = useState([]);

  const okHandler = () => {
    window.location.reload()
  }

  const submitHandler = (values) => {
    setLoadingState(true)
    let coordinateVauel = values.coordinateTxt
    if (coordinateVauel) {
      coordinateVauel = coordinateVauel.toString()
    }
    axios.post(baseUrl + 'index/organization/add', {
      org_name: values.orgName,
      description: values.description,
      coordinate: coordinateVauel,
      icon: values.mapIcon,
      area_id: user.areaId,
      token: user.token
    })
      .then(function (response) {
        setLoadingState(false)
        if (response.data.code === 20000) {
          Modal.success({
            centered: true,
            content: '信息提交成功',
            onOk: okHandler
          })
        } else {
          Modal.warning({
            title: '错误信息',
            centered: true,
            content: response.data.datas.msg,
          })
        }
      })
      .catch(function (error) {
        setLoadingState(false)
        console.log(error);
      });
  }

  useEffect(() => {
    // var canvas = document.getElementById('map')
    // var ctx = canvas.getContext('2d')

    // const img = './assets/images/hc_map.png'
    // const objMap = document.getElementById('wrapMap')
    // objMap.style.backgroundImage = `url(${img})`

    // let coordinateX = 0
    // let coordinateY = 0
    // canvas.addEventListener('click', (event) => {
    //   if(coordinateX > 0 || coordinateY > 0){
    //     ctx.clearRect(coordinateX, coordinateY, 32, 32)
    //   }

    //   let loc = windowTocanvas(canvas, event.clientX, event.clientY)
    //   coordinateX = parseInt(loc.x) - 16
    //   coordinateY = parseInt(loc.y) - 32

    //   console.log(coordinateX, coordinateY)

    //   form.setFieldsValue({
    //     coordinateTxt: coordinateX + ',' + coordinateY
    //   })

    //   let marker = new Image()
    //   marker.onload = () => {
    //     ctx.drawImage(marker, coordinateX, coordinateY, 32, 32)
    //   }
    //   marker.src = "./assets/images/marker.png"
    // })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const windowTocanvas = (canvas, x, y) => {
  //   var bbox = canvas.getBoundingClientRect()
  //   return {
  //     x: x - bbox.left * (canvas.width / bbox.width),
  //     y: y - bbox.top * (canvas.height / bbox.height)
  //   };
  // }
  const getPoint = (e) => {
    let target = e.target;
    let left = Number((e.nativeEvent.offsetX) / target.offsetWidth * 100).toFixed(2) + '%';
    let top = Number((e.nativeEvent.offsetY) / target.offsetHeight * 100).toFixed(2) + '%';
    let position = [left, top]
    setPosition(position)
    form.setFieldsValue({
      coordinateTxt: position
    })
  }
  const imgErr = (e) => {
    let target = e.target;
    target.src = '/uploads/maps/hc.png'
  }
  return (
    <div className="wrap">
      <Header />
      <Sider />
      <div className="container">
        <div className="main-content">
          <Breadcrumb>
            <Breadcrumb.Item>首页</Breadcrumb.Item>
            <Breadcrumb.Item>机构管理</Breadcrumb.Item>
            <Breadcrumb.Item>添加机构</Breadcrumb.Item>
          </Breadcrumb>

          <Spin tip="Loading..." spinning={loading} size="large">
            <Row>
              <Col span={12}><div className="form-area">
                <Form form={form} requiredMark={false} onFinish={submitHandler}>
                  <Form.Item
                    name="orgName"
                    label="机构名称"
                    rules={[
                      {
                        required: true,
                        message: '请输入机构名称',
                      },
                    ]}>
                    <Input
                      placeholder="请输入机构名称"
                    />
                  </Form.Item>
                  <Form.Item
                    name="description" label="机构简介">
                    <Input.TextArea rows={6} placeholder="请输入机构简介" />
                  </Form.Item>
                  <Form.Item
                    hidden={false}
                    name="coordinateTxt"
                    label="地图坐标"
                  >
                    <Input
                      placeholder="请选取地图坐标"
                    />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{
                      offset: 10, span: 24,
                    }}>
                    <Button type="primary" htmlType="submit" size={'large'}>提交信息</Button>
                  </Form.Item>
                </Form>
              </div>
              </Col>
              <Col className="map" span={12}>
                {/* <div className="map-area">
                <div className="wrap-map" id="wrapMap" >
                  <canvas id="map"  width="600" height="600">
                  </canvas>
                </div>
              </div> */}
                <div style={{ position: 'relative' }}>
                  <img
                    onClick={(e) => { getPoint(e) }}
                    src={'/uploads/maps/' + user.areaId + '.png'}
                    style={{ width: '100%' }}
                    onError={imgErr}
                    alt="" />
                  <img
                    alt=""
                    className="marker"
                    style={position.length === 0 ? { display: 'none' } : { display: 'block', top: position[1], left: position[0] }}
                    src='./assets/images/marker.png' />
                </div>
              </Col>
            </Row>
          </Spin>
        </div>
      </div>
    </div>
  )
}

export default AddDepartment