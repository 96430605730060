import React from 'react'
import { Link, useHistory } from "react-router-dom";

import { Menu } from 'antd';
import { LogoutOutlined, KeyOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';

import storage from '../model/storage'

const { SubMenu } = Menu;

const Header = () => {
  const history = useHistory();
  let user = storage.get('user')

  const signoutHandler = () => {
    storage.remove('user')
    history.push('/signin')
  }
  return (
    <div className="header">
      <div className="logo"><Link to="/">党员监察对象<p>信息管理系统</p></Link></div>
      <div className="area-name">{user.areaName}</div>
      <div className="header-right">
        <Menu selectedKeys={['aaa']} mode="horizontal" style={{ borderBottom: 0 }}>
          <Menu.Item key="1" ><HomeOutlined /><Link to="/">后台首页</Link></Menu.Item>
          <SubMenu key="2" title="个人中心" icon={<UserOutlined />}>
            <Menu.Item><KeyOutlined /><Link to="/edit_pwd">修改密码</Link></Menu.Item>
            <Menu.Item onClick={signoutHandler}><LogoutOutlined />退出登录</Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </div>
  )
}

export default Header