import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Home from './pages/home/index';
import Intro from './pages/home/intro';
import AddStep1 from './pages/archives/add-step1';
import AddStep2 from './pages/archives/add-step2';
import AddStep3 from './pages/archives/add-step3';
import Signin from './pages/signin/index';
import AddDepartment from './pages/department/add';
import ListDepartment from './pages/department/list';
import EditDepartment from './pages/department/edit';
import PrivateRoute from './components/private-route';
import NoticeList from './pages/notice/list';
import NoticeContent from './pages/notice/info';
import MemberList from './pages/archives/list';
import EditMember from './pages/archives/edit';
import EditPassowrd from './pages/user/edit';
import PersonnelTransfer from './pages/archives/transfer';
import MemberInfo from './pages/archives/info';
import PrintMember from './pages/archives/print-member';
import MultiEdit from './pages/archives/multi-edit';
import zhCN from 'antd/lib/locale/zh_CN';

const App = () => {
  /* "homepage": "/app"
  <Router basename="/a/">*/
  useEffect(() => {
    console.log(document)
  })
  return (
    <Router basename="/app/">
      <Switch>
        <PrivateRoute exact path="/" component={Home}></PrivateRoute>
        <PrivateRoute exact path="/intro" component={Intro}></PrivateRoute>
        <PrivateRoute exact path="/add_step1" component={AddStep1}></PrivateRoute>
        <PrivateRoute exact path="/add_step2" component={AddStep2}></PrivateRoute>
        <PrivateRoute exact path="/add_step3" component={AddStep3}></PrivateRoute>
        <PrivateRoute exact path="/add_department" component={AddDepartment}></PrivateRoute>
        <PrivateRoute exact path="/list_department" component={ListDepartment}></PrivateRoute>
        <PrivateRoute exact path="/edit_department" component={EditDepartment}></PrivateRoute>
        <PrivateRoute exact path="/list_notice" component={NoticeList}></PrivateRoute>
        <PrivateRoute exact path="/notice_content" component={NoticeContent}></PrivateRoute>
        <PrivateRoute exact path="/list_member" component={MemberList}></PrivateRoute>
        <PrivateRoute exact path="/edit_member" component={EditMember}></PrivateRoute>
        <PrivateRoute exact path="/personnel_transfer" component={PersonnelTransfer}></PrivateRoute>
        <PrivateRoute exact path="/edit_pwd" component={EditPassowrd}></PrivateRoute>
        <PrivateRoute exact path="/member_info" component={MemberInfo}></PrivateRoute>
        <PrivateRoute exact path="/print_member/:id" component={PrintMember}></PrivateRoute>
        <PrivateRoute exact path="/mult_edit" component={MultiEdit}></PrivateRoute>
        <Route exact path="/signin" component={Signin}></Route>
      </Switch>
    </Router>
  )
}

export default App