import React, { useState, useEffect } from 'react';
import { Button, Breadcrumb, Form, Input, Row, Col, Spin, Modal} from 'antd';
import { useHistory } from "react-router-dom";

import Header from '../../components/header';
import Sider from '../../components/sider';
import axios from "axios"
import global from '../../model/config'
import storage from '../../model/storage'

const BaseIntro = (props) => {
    let user = storage.get('user')
    let baseUrl = global.baseUrl;
    let params = props.location.params;
    let history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoadingState] = useState(false);

    const okHandler = () => {
        history.goBack();
    }
    const submitHandler = (values)=>{
        setLoadingState(true)
        axios.post(baseUrl + 'index/index/edit_area', {
            area_name: values.area_name,
            intro: values.intro,
            area_id: values.id,
            token:user.token
        })
        .then(function (response) {
        setLoadingState(false)
        if(response.data.code === 20000){
            Modal.success({
            centered:true,
            content: '信息提交成功',
            onOk: okHandler
            })
        }else{
            Modal.warning({
            title: '错误信息',
            centered:true,
            content: response.data.datas.msg,
            })
        }
        })
        .catch(function (error) {
        setLoadingState(false)
        console.log(error);
        });
    }
    useEffect(()=>{
        if(!params){
            axios.post(baseUrl + 'index/index/get_base_info', {
                area_id:user.areaId,
                token:user.token
            }).then((re)=>{
                if(re.data.code === 20000){
                    form.setFieldsValue(re.data.datas.list)
                    console.log(re.data.datas.list)
                }
            })
        }else{
            form.setFieldsValue(params)
        }
        
    })

    return (
        <div className="wrap">
        <Header />
        <Sider />
        <div className="container">
            <div className="main-content">
                <Breadcrumb>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>档案管理</Breadcrumb.Item>
                    <Breadcrumb.Item>档案详情</Breadcrumb.Item>
                </Breadcrumb>

                <Spin tip="Loading..." spinning={loading}  size="large">
                <Row gutter={[16, 16]} justify="space-around" align="middle">
                    <Col span={20} style={{marginTop: 16}}>
                    <Form form={form} onFinish = {submitHandler}>
                        <Form.Item name="id" hidden>
                            <Input rows={4}/>
                        </Form.Item>
                        <Form.Item name="area_name" label="镇区名称">
                            <Input/>
                        </Form.Item>
                        <Form.Item name="intro" label="镇区简介">
                            <Input.TextArea rows={4}/>
                        </Form.Item>
                        <Form.Item
                        wrapperCol={{
                            offset: 10, span: 24,
                        }}>
                        <Button type="primary" htmlType="submit">
                        修改
                        </Button>
                        </Form.Item>
                    </Form>
                    </Col>  
                </Row>
                </Spin>
            </div>
        </div>
        </div>
    )
}

export default BaseIntro