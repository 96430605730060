import React from 'react'
import ReactDOM from 'react-dom'
import App from './app';

import 'antd/dist/antd.css';
import './assets/css/app.css'

ReactDOM.render(
  <App />,
  document.getElementById('root')
);