import React, { useState } from 'react';

import { Button, Form, Input, Breadcrumb, Row, Col, Modal,Spin } from 'antd';

import Header from '../../components/header';
import Sider from '../../components/sider';
import global from '../../model/config'

import axios from "axios"

import './edit.css'
import storage from '../../model/storage'

const EditPassowrd = () => {
  storage.set('selectedKeys', 'home')
  
  const [form] = Form.useForm();

  let baseUrl = global.baseUrl;
  let user = storage.get('user')
  const [loading, setLoadingState] = useState(false);

  const okHandler = () => {
    window.location.reload()
  }

  const submitHandler = (values) => {
    setLoadingState(true)

    axios.post(baseUrl + 'index/user/change_pwd', {
      password: values.oldPassword,
      new_pwd: values.newPassword,
      token:user.token
    })
    .then(function (response) {
      setLoadingState(false)
      if(response.data.code === 20000){
        Modal.success({
          centered:true,
          content: '信息提交成功',
          onOk: okHandler
        })
      }else{
        Modal.warning({
          title: '错误信息',
          centered:true,
          content: response.data.datas.msg,
        })
      }
    })
    .catch(function (error) {
      setLoadingState(false)
      console.log(error);
    });
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <div className="wrap">
      <Header />
      <Sider />
      <div className="container">
        <div className="main-content">
          <Breadcrumb>
            <Breadcrumb.Item>首页</Breadcrumb.Item>
            <Breadcrumb.Item>用户管理</Breadcrumb.Item>
            <Breadcrumb.Item>修改密码</Breadcrumb.Item>
          </Breadcrumb>

          <Spin tip="Loading..." spinning={loading}  size="large">
          <Row>
            <Col span={8}><div className="user-form-area">
              <Form 
                {...layout}
                form={form} 
                requiredMark={true} 
                onFinish = {submitHandler} 
                  >
                <Form.Item 
                  hasFeedback
                  name="oldPassword"
                  label="旧密码"
                  rules={[
                    {
                      required: true,
                      message: '请输入旧密码',
                    },
                  ]}>
                  <Input.Password
                    placeholder="请输入旧密码"
                  />
                </Form.Item>
                <Form.Item
                hasFeedback
                  name="newPassword"
                  label="新密码"
                  rules={[
                    {
                      required: true,
                      message: '请输入新密码',
                    },
                  ]}>
                  <Input.Password
                    placeholder="请输入新密码"
                  />
                </Form.Item>
                <Form.Item
                  name="confPassword"
                  label="新密码确认"
                  dependencies={['newPassword']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: '请确认新密码',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('newPassword') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject('两次输入的密码不一致');
                      },
                    }),
                  ]}>
                  <Input.Password 
                    placeholder="请输入新密码"
                  />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                      offset: 8, span: 24,
                    }}>
                  <Button type="primary"  htmlType="submit" size={'large'}>保存修改</Button>
                </Form.Item>
              </Form>
            </div>
            </Col>
          </Row>
          </Spin>
        </div>
      </div>
    </div>
  )
}

export default EditPassowrd