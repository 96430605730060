import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { Button, Breadcrumb, Table, Space, Tag, Modal } from 'antd';

import Header from '../../components/header';
import Sider from '../../components/sider';
import global from '../../model/config'

import axios from "axios"

import storage from '../../model/storage'
import './list.css'

const NoticeList = () => {
  const columns = [
    {
      title: '标题',
      dataIndex: 'title',
      key: 'title',
      width: '20%',
    },
    {
      title: '发布时间',
      dataIndex: 'gmt_create',
      key: 'gmt_create',
      width: '20%',
    },
    {
      title: '阅读情况',
      dataIndex: 'reas_txt',
      key: 'reas_txt',
      width: '20%',
      render: (text, record) => {
        let color = 'green'
        if (record.is_read === 0) {
          color = 'volcano'
        } else {
          color = 'green'
        }
        return (
          <Tag color={color} key={record.id}>
            {text}
          </Tag>);
      },
    },
    {
      title: '',
      width: '20%',
      key: 'action',
      render: (record) => (
        <Space size="middle">
          <Button type="link" onClick={() => detailHandler(record.id)}>详情</Button>
          {/* <Button type="link" onClick={()=>{}}>回复</Button> */}
        </Space>
      )
    },
  ];

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [loading, setLoading] = useState(false);
  const [noticeList, setNoticeData] = useState([]);

  storage.set('selectedKeys', 'noticeList')

  let history = useHistory();

  const detailHandler = noticeId => {
    history.push({
      pathname: '/notice_content',
      state: {
        noticeId: noticeId
      },
    })
  }

  const handleTableChange = (pagination) => {
    fetch({ pagination });
  };

  let baseUrl = global.baseUrl;
  let user = storage.get('user')

  const fetch = async (params = {}) => {
    setLoading(true)
    const res = await axios.post(baseUrl + 'index/notices/get_info_list', {
      curr_page: params.pagination.current,
      limit: params.pagination.pageSize,
      token: user.token
    })
    if (res.data.code === 20000) {
      setNoticeData(res.data.datas.list);
      setPagination({
        ...params.pagination,
        total: res.data.datas.total,
      });
    } else {
      Modal.warning({
        title: '错误信息',
        centered: true,
        content: res.data.datas.msg,
        onOk: () => {
          Modal.destroyAll()
          history.push({
            pathname: '/signin'
          })
        }
      })
    }

    setLoading(false)
  };

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      fetch({ pagination })
    }
    return () => isSubscribed = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="wrap">
      <Header />
      <Sider />
      <div className="container">
        <div className="main-content">
          <Breadcrumb>
            <Breadcrumb.Item>首页</Breadcrumb.Item>
            <Breadcrumb.Item>通告管理</Breadcrumb.Item>
            <Breadcrumb.Item>通告列表</Breadcrumb.Item>
          </Breadcrumb>

          <div className="notice-list">
            <Table
              columns={columns}
              dataSource={noticeList}
              pagination={pagination}
              loading={loading}
              onChange={handleTableChange}
              rowKey={noticeList => noticeList.id} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoticeList