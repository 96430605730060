import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { Breadcrumb, Steps, Card, Row, Col, Divider, Button, Select, Spin, Modal, Tag } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import Header from '../../components/header';
import Sider from '../../components/sider';

import './add.css'
import storage from '../../model/storage'

import global from '../../model/config'
import axios from "axios"

function PersonnelTransfer(props) {
  const { Step } = Steps
  const { confirm } = Modal

  const idCard = props.location.state.idCard
  const member = props.location.state.member

  const [memberName, setMemberName] = useState(null);
  const [sex, setSex] = useState(null);
  const [areaName, setAreaName] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [workStatusColor, setWorkStatusColor] = useState(null);
  const [workStatusTxt, setWorkStatusTxt] = useState(null);
  const [canInflow, setCanInflow] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);

  const [orgListData, setOrgList] = useState([]);

  let history = useHistory();
  const { Option } = Select;

  storage.set('selectedKeys', 'addArchives')

  const onReturn = () => {
    history.push({
      pathname: '/add_step1',
    })
  };

  const ininPage = () => {
    setMemberName(member.member_name)
    setSex(member.sex)
    setAreaName(member.area)

    setWorkStatusTxt(member.work_status_txt)
    setCanInflow(member.can_inflow)
    if (member.work_status === 2 || member.can_inflow === 1) {
      setWorkStatusColor('green')
    } else {
      setWorkStatusColor('volcano')
    }
  };

  let baseUrl = global.baseUrl;
  let user = storage.get('user')

  const getOrgList = async () => {
    setSelectLoading(true)
    const res = await axios.post(baseUrl + 'index/member/get_org_list', {
      token: user.token
    })
    if (res.data.code === 20000) {
      let orgList = res.data.datas.list

      orgList.forEach(function (item) {
        if (item.id === member.org_id) {
          setOrgId(member.org_id)
        }
      });

      setOrgList(res.data.datas.list)

    } else {
      Modal.warning({
        title: '错误信息',
        centered: true,
        content: res.data.datas.msg,
      })
    }

    setSelectLoading(false)
  };

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getOrgList()
      ininPage()
    }
    return () => isSubscribed = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  function handleChange(value) {
    setOrgId(value)
  }

  const saveHandler = () => {
    if (!orgId) {
      Modal.warning({
        title: '错误信息',
        centered: true,
        content: '请选择工作单位',
      })
      return false
    }
    confirm({
      title: '确定要提交修改?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      centered: true,
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        setSelectLoading(true)
        axios.post(baseUrl + 'index/member/enter', {
          id_card: idCard,
          org_id: orgId,
          token: user.token
        })
          .then(function (response) {
            setSelectLoading(false)
            if (response.data.code === 20000) {
              history.push({
                pathname: '/add_step3',
                params: {
                  memberId: member.member_id
                },
              })
            } else {
              Modal.warning({
                title: '错误信息',
                centered: true,
                content: response.data.datas.msg,
              })
            }
          })
      },
      onCancel() {

      },
    });
  }

  return (
    <div className="wrap">
      <Header />
      <Sider />
      <div className="container">
        <div className="main-content">
          <div className="main-content">
            <div className="breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>首页</Breadcrumb.Item>
                <Breadcrumb.Item>档案管理</Breadcrumb.Item>
                <Breadcrumb.Item>人员转入</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="content">
              <Spin tip="Loading..." spinning={selectLoading} size="large">
                <Steps current={1} >
                  <Step title="Step 1" description="查询人员信息" />
                  <Step title="Step 2" description="人员转入" />
                  <Step title="Step 3" description="完成" />
                </Steps>
                <div style={{ marginTop: '35px' }}>
                  <Row>
                    <Col span={12} offset={6}>
                      <Card type="inner" title="人员信息" size="small" >
                        <Row>
                          <Col span={6}>姓名</Col><Col span={18}>{memberName}</Col>
                          <Divider />
                          <Col span={6}>性别</Col><Col span={18}>{sex === '1' ? '男' : '女'}</Col>
                          <Divider />
                          <Col span={6}>身份证号码</Col><Col span={18}>{idCard}</Col>
                          <Divider />
                          <Col span={6}>所在单位/地区</Col><Col span={18}>{areaName}</Col>
                          <Divider />
                          <Col span={6}>工作单位</Col>
                          <Col span={18}>
                            <Select
                              showSearch
                              placeholder="-请选择-"
                              style={{ width: '300px' }}
                              onChange={handleChange}
                              value={orgId}
                            >
                              {
                                orgListData.map((item) => (
                                  <Option key={item.id} value={item.id}>{item.org_name}</Option>
                                ))
                              }
                            </Select>

                          </Col>
                          <Divider />
                          <Col span={6}>状态</Col><Col span={18}>
                            <Tag color={workStatusColor}>{workStatusTxt}</Tag>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: '15px', textAlign: 'center' }}>
                    <Col span={12} offset={6}>
                      <Button type="primary" disabled={!canInflow} onClick={saveHandler}>转入</Button>
                      <Button style={{ marginLeft: '15px' }} onClick={onReturn}>返回</Button>
                    </Col>
                  </Row>
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonnelTransfer