import React from 'react';
import { Button } from 'antd';

import TableMember from './table-member';

const PrintMember = (props) => {
  const url = props.history.location.pathname
  const arry = url.split("/");
  const memberId = arry[arry.length - 1]

  const printHanlder = () => {
    window.print()
  }

  return (
    <div style={{ width: '100%', backgroundColor:'#FFFFFF', padding: '25px' }}>
      <div style={{ width: '1000px', margin: '0 auto' }}>
        <h1 style={{
          marginBottom: '25px',
          textAlign: 'center',
          letterSpacing: '0.5em'
        }}>监察对象信息登记表</h1>
        <TableMember memberId={memberId} />
        <div style={{ width: '100%', textAlign: 'center', padding:'25px'}}>
          <Button type="primary" htmlType='button' onClick={printHanlder} id='btn-print'>打印</Button>
        </div>
      </div>
    </div>
  )
}

export default PrintMember