import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { Button, Form, Input, Breadcrumb, Row, Col, Modal, Spin } from 'antd';

import Header from '../../components/header';
import Sider from '../../components/sider';

import global from '../../model/config'

import axios from "axios"
import './add.css'
import storage from '../../model/storage'

const EditDepartment = (props) => {
  const orgId = props.location.state.orgId
  storage.set('selectedKeys', 'deptList')
  let history = useHistory();

  let baseUrl = global.baseUrl;
  let user = storage.get('user')

  const [loading, setLoadingState] = useState(false);
  const [orgCoordinate, setOrgCoordinate] = useState(null);
  const [position, setPosition] = useState([]);

  const [form] = Form.useForm();
  const formInit = async () => {
    setLoadingState(true)
    const res = await axios.post(baseUrl + 'index/organization/get_org_byid', {
      org_id: orgId,
      token: user.token
    })
    if (res.data.code === 20000) {
      let result = res.data.datas.organization

      form.setFieldsValue({
        orgName: result.org_name,
        description: result.description,
        coordinateTxt: result.coordinate,
        mapIcon: !result.icon || result.icon.toString()
      })
      setPosition(result.coordinate.split(','))
      setOrgCoordinate(result.coordinate)
    } else {
      Modal.warning({
        title: '错误信息',
        centered: true,
        content: res.data.datas.msg,
      })
    }
    setLoadingState(false)
  }

  // const getCoordinate = (coordinate = null) => {
  //   form.setFieldsValue({
  //     coordinateTxt: coordinate
  //   })
  // }

  const okHandler = () => {
    //window.location.reload()
    history.goBack()
  }

  const submitHandler = (values) => {
    setLoadingState(true)
    let coordinateVauel = values.coordinateTxt
    if (coordinateVauel) {
      coordinateVauel = coordinateVauel.toString()
    }
    axios.post(baseUrl + 'index/organization/edit', {
      org_id: orgId,
      org_name: values.orgName,
      description: values.description,
      coordinate: coordinateVauel,
      icon: values.mapIcon,
      area_id: user.areaId,
      token: user.token
    })
      .then(function (response) {
        setLoadingState(false)
        if (response.data.code === 20000) {
          Modal.success({
            centered: true,
            content: '信息提交成功',
            onOk: okHandler
          })
        } else {
          Modal.warning({
            title: '错误信息',
            centered: true,
            content: response.data.datas.msg,
          })
        }
      })
  }

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      formInit()
    }
    return () => isSubscribed = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const returnHandler = () => {
    history.goBack()
  }
  const getPoint = (e) => {
    let target = e.target;
    let left = Number((e.nativeEvent.offsetX) / target.offsetWidth * 100).toFixed(2) + '%';
    let top = Number((e.nativeEvent.offsetY) / target.offsetHeight * 100).toFixed(2) + '%';
    let position = [left, top]
    setPosition(position)
    form.setFieldsValue({
      coordinateTxt: position
    })
  }
  const imgErr = (e) => {
    let target = e.target;
    target.src = '/uploads/maps/hc.png'
  }
  return (
    <div className="wrap">
      <Header />
      <Sider />
      <div className="container">
        <div className="main-content">
          <Breadcrumb>
            <Breadcrumb.Item>首页</Breadcrumb.Item>
            <Breadcrumb.Item>机构管理</Breadcrumb.Item>
            <Breadcrumb.Item>编辑机构信息</Breadcrumb.Item>
          </Breadcrumb>

          <Spin tip="Loading..." spinning={loading} size="large">
            <Row>
              <Col span={12}><div className="form-area">
                <Form form={form} requiredMark={false} onFinish={submitHandler}>
                  <Form.Item
                    name="orgName"
                    label="机构名称"
                    rules={[
                      {
                        required: true,
                        message: '请输入机构名称',
                      },
                    ]}>
                    <Input
                      placeholder="请输入机构名称"
                    />
                  </Form.Item>
                  <Form.Item
                    name="description" label="机构简介">
                    <Input.TextArea rows={6} placeholder="请输入机构简介" />
                  </Form.Item>
                  <Form.Item
                    name="coordinateTxt"
                    label="地图坐标"
                    rules={[
                      {
                        required: true,
                        message: '请选取地图坐标',
                      },
                    ]}>
                    <Input
                      placeholder="请选取地图坐标"
                    />
                  </Form.Item>
                  {/* <Form.Item label="图标样式" name="mapIcon" initialValue='1'>
                    <Radio.Group   >
                      <Radio.Button value="1">国徽</Radio.Button>
                      <Radio.Button value="2">党徽</Radio.Button>
                    </Radio.Group>
                  </Form.Item> */}
                  <Form.Item
                    wrapperCol={{
                      offset: 8, span: 24,
                    }}>
                    <Button type="primary" htmlType="submit" size={'large'}>提交信息</Button>
                    <Button htmlType="button" size={'large'} style={{ marginLeft: '15px' }} onClick={returnHandler}>返回</Button>
                  </Form.Item>
                </Form>
              </div>
              </Col>
              <Col span={12}>
                {/* <Image
                width="100%"
                preview={false}
                onClick={(e)=>{getPoint(e)}}
                src={'/uploads/maps/'+user.areaId+'.png'}
                /> */}
                <div style={{ position: 'relative' }}>
                  <img
                    onClick={(e) => { getPoint(e) }}
                    src={'/uploads/maps/' + user.areaId + '.png'}
                    style={{ width: '100%' }}
                    onError={imgErr}
                    alt="" />
                  <img
                    alt=""
                    className="marker"
                    style={position.length === 0 ? { display: 'none' } : { display: 'block', top: position[1], left: position[0] }}
                    src='./assets/images/marker.png' />
                </div>
              </Col>
            </Row>
          </Spin>
        </div>
      </div>
    </div>
  )
}

export default EditDepartment