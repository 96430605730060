import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { Button, Breadcrumb, Spin,Modal, Input } from 'antd';

import Header from '../../components/header';
import Sider from '../../components/sider';
import global from '../../model/config'

import axios from "axios"
import storage from '../../model/storage'

import './info.css'

const NoticeContent = (props) => {
  const noticeId = props.location.state.noticeId

  storage.set('selectedKeys', 'noticeList')
  let history = useHistory();

  let baseUrl = global.baseUrl;
  let user = storage.get('user')

  const [loading, setLoadingState] = useState(false);
  const [articleTitle, setArticleTitle] = useState(null);
  const [createDate, setCreateDate] = useState(null);
  const [clickNum, setClickNum] = useState(null);
  const [articleContent, setArticleContent] = useState(null);
  const [reply, setReply] = useState('');
  
  const pageInit = async () => {
    setLoadingState(true)
    const res = await axios.post(baseUrl + 'index/notices/get_info_byid', {
      notice_id: noticeId,
      token:user.token
    })
    
    if(res.data.code === 20000){
      let result = res.data.datas.notice
      setArticleTitle(result.title)
      setCreateDate(result.gmt_create)
      setClickNum(result.click)
      setArticleContent(result.content)
      if(!!res.data.datas.receive) setReply(res.data.datas.receive.reply)

    }else {
      Modal.warning({
        title: '错误信息',
        centered: true,
        content: res.data.datas.msg,
      })
    }
    
    setLoadingState(false)
  }

  useEffect(() => {
    let isSubscribed = true;
    if(isSubscribed){
      pageInit()
    }
    return () => isSubscribed = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const returnHandler = () => {
    history.goBack()
  }
  const subReply = ()=>{
    Modal.confirm({
      content: '是否确定回复信息',
      okText: '确定',
      onOk: ()=>{
        axios.post(baseUrl + 'index/notices/create_info_reply', {
          notice_id: noticeId,
          reply: reply,
          token:user.token
        }).then(function(res){
          if(res.code === 20000){
            history.goBack()
          }
        })
      },
      cancelText: '取消',
      onCancel: ()=>{}
    })
  }

  return (
    <div className="wrap">
      <Header />
      <Sider />
      <div className="container">
        <div className="main-content">
          <Breadcrumb>
            <Breadcrumb.Item>首页</Breadcrumb.Item>
            <Breadcrumb.Item>通告管理</Breadcrumb.Item>
            <Breadcrumb.Item>通告详情</Breadcrumb.Item>
          </Breadcrumb>

          <Spin tip="Loading..." spinning={loading}  size="large" delay={500}>
            <div className="article-area">
              <h1 className="article-title">{articleTitle}</h1>
              <div className="create-date">
                  {createDate}
              </div> 
              <div className="click">
                  阅读次数：{clickNum}
              </div> 
              <div className="article-content" dangerouslySetInnerHTML={{__html: articleContent}}></div>
            </div>
            <div className="btn-area">
            <Button  htmlType="button" className="btn-return" onClick={returnHandler}>返回</Button>
            <Button type="primary" onClick={subReply}>回复</Button>
            <Input.TextArea 
            value={reply} 
            autoSize={{ minRows: 5, maxRows: 10 }}
            onChange={({ target: { value } })=>{setReply(value)}}/>
            </div>
          </Spin>
        </div>
      </div>
    </div>
  )
}

export default NoticeContent