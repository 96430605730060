import React from 'react'
import { Link, withRouter } from "react-router-dom";

import storage from '../model/storage'

import { Menu } from 'antd';
const { SubMenu } = Menu;

const Sider =  withRouter(() => {
  
  let selectedKeys = storage.get('selectedKeys')

  if(!selectedKeys){
    selectedKeys = 'home'
  }

  const clickHandle= (e) => {
    storage.set('openKeys', e.keyPath[1])
  }

  return (
    <div className="sider">
      <div className="side-scroll">
        <Menu
          style={{ width: 200 }}
          mode="inline"
          theme="dark"
          selectedKeys={selectedKeys}
          defaultOpenKeys={["sub1", "sub2", "sub3", "sub4", "sub5"]}
          onClick = {clickHandle}
        >
          <SubMenu key="sub1" title="系统首页">
            <Menu.Item key="home"><Link to="/">基础信息</Link></Menu.Item>
          </SubMenu>
          <SubMenu key="sub2" title="组织机构管理">
            <Menu.Item key="addDept"><Link to="/add_department">添加机构</Link></Menu.Item>
            <Menu.Item key="deptList"><Link to="/list_department">查看机构列表</Link></Menu.Item>
          </SubMenu>
          <SubMenu key="sub3" title="档案管理">
            <Menu.Item key="addArchives"><Link to="/add_step1">添加转入人员</Link></Menu.Item>
            <Menu.Item key="archivesList"><Link to="/list_member">档案列表</Link></Menu.Item>
          </SubMenu>
          <SubMenu key="sub4" title="通告管理">
            <Menu.Item key="noticeList"><Link to="/list_notice">查看通告</Link></Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </div>
  )
})

export default Sider