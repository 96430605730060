import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { Breadcrumb, Form, Input, Button, Steps, Modal, Upload } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import Header from '../../components/header';
import Sider from '../../components/sider';

import './add.css'
import storage from '../../model/storage'

import axios from "axios"
import global from '../../model/config'
import mammoth from 'mammoth';

function AddStep1() {
  storage.set('selectedKeys', 'addArchives')

  const { Step } = Steps
  let baseUrl = global.baseUrl
  let user = storage.get('user')
  let history = useHistory()

  const [btnLoading, setLoading] = useState(false);
  const [member, setMember] = useState();
  const [nextstep, setNextStep] = useState();
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const imWord = () => {
    let file = document.getElementById('file');
    // let file = <Input type="file" />;
    // console.log(file)
    file.click();
  }
  // 获取word文件
  const getPath = (e) => {
    let file = e.target.files[0];
    var reader = new FileReader();

    reader.onload = function (loadEvent) {
      var arrayBuffer = loadEvent.target.result;//arrayBuffer     
      mammoth.convertToHtml({ arrayBuffer: arrayBuffer })
        .then(function (result) {
          let html = document.createElement('div');
          html.innerHTML = result.value;
          // getMember转成member对象
          ifWordData(getMember(html))
        })
        .done();
    };

    reader.readAsArrayBuffer(file);
  }
  // 询问是否导入word数据
  const ifWordData = (member) => {
    confirm({
      title: '提示信息',
      icon: <ExclamationCircleOutlined />,
      content: '是否从word导入数据',
      okText: '是，导入word',
      cancelText: '取消',
      centered: true,
      onOk() {
        upLoad(member.idcard, member)
      },
      onCancel() {
        form.setFieldsValue({
          idcard: '',
          file: undefined
        })
      }
    })
  }
  // html转成member对象
  const getMember = (html) => {
    let table = html.querySelector('tbody');
    const getp = (x, y) => {
      let ps = table.rows[x].cells[y];
      let p = '';
      if (ps.childNodes.length === 1) {
        p = ps.childNodes[0].childNodes[0]
      } else {
        for (let i in ps.childNodes) {
          p += ps.childNodes[i].innerHTML || '';
        }
      }
      switch (p.nodeName) {
        case '#text':
          return p.nodeValue;
          break;
        case 'IMG':
          return p.src;
          break;
        default:
          return p;
      }
    }
    const relationship = () => {
      let list = [];
      for (let i = 12; i < 17; i++) {
        list.push({
          kinsfolk: getp(i, 0),
          name: getp(i, 1),
          politicalOutlook: getp(i, 3),
          work: getp(i, 4)
        })
      }
      return list;
    }
    form.setFieldsValue({
      idcard: getp(7, 1)
    })
    let member = {
      base64_img: getp(0, 6),
      member_name: getp(0, 1),
      sex: (getp(0, 3) === '男') ? '1' : '2',
      birthday: getp(0, 5),
      nation: getp(1, 1),
      native_place: getp(1, 3),
      starting_work_date: getp(1, 5),
      political_outlook: getp(2, 1),
      political_party_date: getp(2, 3),
      class_of_positions: getp(2, 5),
      duties: getp(3, 1),
      unit_class: getp(3, 3),
      identity: getp(3, 5),
      education: getp(4, 3),
      school: getp(4, 5),
      deputy_to_npc: '',
      member_of_the_cppcc: '',
      representatives_of_the_cpc: '',
      member_of_the_party_committee: '',
      dept_classification: '',
      dept_name: '',
      authority_of_cadre_management: '',
      object_of_supervision: '',
      passport: getp(4, 1),
      eep_to_hk_and_macau: getp(5, 0),
      current_duties: '',
      idcard: getp(7, 1),
      contact: getp(7, 3),
      address: getp(9, 1),
      resume: getp(10, 1),
      org_id: '',
      relationship: relationship()
    }
    return member;
    // console.log(table.querySelector('tr:nth-child(1) td:nth-child(2)'))
  }
  const onFinish = ({ idcard }) => {
    upLoad(idcard)
  }
  const upLoad = (idcard, data) => {

    setLoading(true)
    axios.post(baseUrl + 'index/member/get_memberbyidcard', {
      idcard: idcard,
      token: user.token
    })
      .then(function (response) {
        setLoading(false)
        if (response.data.code === 20000) {
          let res = response.data.datas
          let member = data || res.member
          if (res.member.work_status === 4) {
            history.push({
              pathname: '/edit_member',
              state: {
                memberId: res.member.member_id
              },
            })
          } else if (res.is_found) {
            let tran = () => {
              history.push({
                pathname: '/personnel_transfer',
                state: {
                  idCard: idcard,
                  member: member
                },
              })
            }
            if (data) {
              confirm({
                title: '提示信息',
                icon: <ExclamationCircleOutlined />,
                content: '该用户已存在，无法导入数据',
                okText: '取消',
                cancelText: '编辑该用户信息',
                onOk() {
                  // tran()
                  // member = { ...res.member, ...data }
                  // console.log(member)
                  // history.push({
                  //   pathname: '/edit_member',
                  //   state: {
                  //     memberId: res.member.member_id,
                  //     member: member
                  //   },
                  // })
                  window.location.reload();
                },
                onCancel() {
                  // tran()
                  history.push({
                    pathname: '/edit_member',
                    state: {
                      memberId: res.member.member_id
                    },
                  })
                }
              })
            } else {
              tran()
            }
          } else {
            confirm({
              title: '提示信息',
              icon: <ExclamationCircleOutlined />,
              content: '身份证号码作为唯一身份标识，一旦提交将不能修改，请仔细核对',
              centered: true,
              okText: '确定',
              cancelText: '取消',
              onOk() {
                history.push({
                  pathname: '/add_step2',
                  state: {
                    idCard: idcard,
                    member: member
                  },
                })
              }
            });
          }
        } else {
          Modal.warning({
            title: '错误信息',
            centered: true,
            content: response.data.datas.msg,
          })
        }
      })

  };
  return (
    <div className="wrap">
      <Header />
      <Sider />
      <div className="container">
        <div className="main-content">
          <div className="main-content">
            <div className="breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>首页</Breadcrumb.Item>
                <Breadcrumb.Item>档案管理</Breadcrumb.Item>
                <Breadcrumb.Item>转入人员</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="content">
              <Steps current={0} >
                <Step title="Step 1" description="查询人员信息" />
                <Step title="Step 2" description="人员转入" />
                <Step title="Step 3" description="完成" />
              </Steps>
              <div className="searchFormArea">
                <Form
                  form={form}
                  name="memberSearch"
                  onFinish={onFinish}
                  layout={"inline"}
                >
                  <Form.Item
                    name="idcard"
                    rules={[{
                      required: true,
                      message: '身份证号码格式不正确',
                      pattern: /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|30|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/
                    }]
                    }
                    style={{ width: '50%' }}
                  >
                    <Input placeholder="请输入身份证号码" />
                  </Form.Item>

                  <Form.Item >
                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} loading={btnLoading}>
                      查询
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" danger onClick={imWord}>导入Word</Button>
                  </Form.Item>
                  <Form.Item name="file" hidden>
                    <Input id="file" type="file" onChange={e => getPath(e)} />
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddStep1