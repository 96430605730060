import React from 'react';

import { Breadcrumb, Steps } from 'antd';

import ArchivesForm from './archives-form';
import Header from '../../components/header';
import Sider from '../../components/sider';

import './add.css'
import storage from '../../model/storage'

function AddStep2(props) {
  const { Step } = Steps
  const { idCard, member } = props.location.state

  storage.set('selectedKeys', 'addArchives')

  return (
    <div className="wrap">
      <Header />
      <Sider />
      <div className="container">
        <div className="main-content">
          <div className="main-content">
            <div className="breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>首页</Breadcrumb.Item>
                <Breadcrumb.Item>档案管理</Breadcrumb.Item>
                <Breadcrumb.Item>添加人员</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="content">
              <Steps current={1} >
                <Step title="Step 1" description="查询人员信息" />
                <Step title="Step 2" description="人员转入" />
                <Step title="Step 3" description="完成" />
              </Steps>
              <div style={{ marginTop: '15px' }}>
                <ArchivesForm id_card={idCard} member_id={0} member={member} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddStep2