import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { Form, Row, Col, Input, Button, Radio, DatePicker, Select, Upload, Space, Spin, Modal, notification, InputNumber } from 'antd';
import { LoadingOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

import axios from "axios"
import global from '../../model/config'
import storage from '../../model/storage'

import './form.css'

function ArchivesForm(props) {
  const [politicalOutlookData, setPoliticalOutlook] = useState([]);
  const [classOfPositionsData, setClassOfPositions] = useState([]);
  const [educationalLevelData, setEducationalLevel] = useState([]);
  const [identityData, setIdentity] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [loading, setUploadLoading] = useState(false);
  const [nationData, setNation] = useState([]);
  const [deputyToNpcData, setDeputyToNpc] = useState([]);
  const [memberOfTheCppccData, setMemberOfTheCppcc] = useState([]);
  const [representativesOfTheCpcData, setRepresentativesOfTheCpc] = useState([]);
  const [memberOfThePartyCommitteeData, setMemberOfThePartyCommittee] = useState([]);
  const [deptClassificationData, setDeptClassification] = useState([]);
  const [deptNameInput, setDeptNameInput] = useState(true);
  const [authorityOfCadreManagementData, setAuthorityOfCadreManagement] = useState([]);
  const [objectOfSupervisionData, setObjectOfSupervision] = useState([]);
  const [orgListData, setOrgList] = useState([]);
  const [changeWorkPlace, setChangeWorkPlace] = useState(false);
  const [kinsfolkData, setKinsfolk] = useState([]);

  const { confirm } = Modal;

  const { id_card, member_id, member } = props;

  let baseUrl = global.baseUrl;
  let user = storage.get('user')
  let history = useHistory();

  const [form] = Form.useForm();
  const [formLoading, setLoadingState] = useState(false);
  const { Option } = Select;

  // const fetch = async () => {
  //   setLoadingState(true)
  //   const result = await axios.post(baseUrl + 'index/index/get_info_list', {
  //     token: user.token,
  //     member_id: member_id
  //   })

  //   if (result.data.code === 20000) {
  //     let reslist = result.data.datas.list
  //     let item = result.data.datas.member
  //     setNation(reslist['1'])
  //     setPoliticalOutlook(reslist['2'])
  //     setClassOfPositions(reslist['3'])
  //     setIdentity(reslist['4'])
  //     setEducationalLevel(reslist['6'])
  //     setDeputyToNpc(reslist['7'])
  //     setMemberOfTheCppcc(reslist['8'])
  //     setRepresentativesOfTheCpc(reslist['9'])
  //     setMemberOfThePartyCommittee(reslist['10'])
  //     setDeptClassification(reslist['11'])
  //     setAuthorityOfCadreManagement(reslist['12'])
  //     setObjectOfSupervision(reslist['14'])
  //     setKinsfolk(reslist['13'])
  //     setOrgList(result.data.datas.org_list)

  //     // if (member_id === 0) {
  //     //   setChangeWorkPlace(false)
  //     // } else {
  //     //   setChangeWorkPlace(true)
  //     // }
  //     let setForms = () => {
  //       let birthday = null;
  //       if (item.birthday) {
  //         birthday = moment(item.birthday, 'YYYY-MM-DD')
  //       }

  //       let starting_work_date = null;
  //       if (item.starting_work_date) {
  //         starting_work_date = moment(item.starting_work_date, 'YYYY-MM')
  //       }


  //       let politicalPartyTime = null;
  //       if (item.political_party_time) {
  //         politicalPartyTime = moment(item.political_party_time, 'YYYY-MM')
  //       }

  //       let resume = item.resume;
  //       if (resume) {
  //         resume = resume.replace(/<br\/>/g, '\r\n');
  //       }

  //       if (item.relationship) {
  //         let rs = JSON.parse(item.relationship);
  //         for (let i in rs) {
  //           let birth = rs[i]['birth'];
  //           if (!!birth) rs[i]['birth'] = moment(birth, 'YYYY-MM-DD')
  //         }
  //         form.setFieldsValue({
  //           relationship: rs
  //         })
  //       }

  //       setImageUrl(item.img_url)
  //       setPhoto(item.photo)

  //       form.setFieldsValue({
  //         memberName: item.member_name,
  //         sex: item.sex,
  //         birthday: birthday,
  //         nation: item.nation,
  //         nativePlace: item.native_place,
  //         startingWorkDate: starting_work_date,
  //         politicalOutlook: item.political_outlook,
  //         politicalPartyTime: politicalPartyTime,
  //         classOfPositions: item.class_of_positions,
  //         duties: item.duties,
  //         unitClass: item.unit_class,
  //         identity: item.identity,
  //         areaName: result.data.datas.area_name,
  //         education: item.education,
  //         school: item.school,
  //         deputyToNpc: item.deputy_to_npc,
  //         memberOfTheCppcc: item.member_of_the_cppcc,
  //         representativesOfTheCpc: item.representatives_of_the_cpc,
  //         memberOfThePartyCommittee: item.member_of_the_party_committee,
  //         deptClassification: item.dept_classification,
  //         deptName: item.dept_name,
  //         authorityOfCadreManagement: item.authority_of_cadre_management,
  //         objectOfSupervision: item.object_of_supervision,
  //         passport: item.passport,
  //         eepToHkAndMacau: item.eep_to_hk_and_macau,
  //         currentDuties: item.current_duties,
  //         idCard: item.idcard,
  //         contact: item.contact,
  //         address: item.address,
  //         resume: resume,
  //         // placeOfWork: item.org_name,
  //         orgId: item.org_id,
  //       })
  //     }
  //     if (member_id === 0) {
  //       form.setFieldsValue({
  //         ...member,
  //         areaName: result.data.datas.area_name,
  //         idCard: id_card
  //       })
  //     } else {
  //       setDeptNameInput(false)
  //       if (item.work_status == 4) {
  //         Modal.confirm({
  //           title: '该用户信息已删除，是否重新输入',
  //           icon: <ExclamationCircleOutlined />,
  //           okText: '重新输入',
  //           cancelText: '使用原有信息',
  //           onOk() {
  //             // console.log('OK');
  //           },
  //           onCancel() {
  //             setForms()
  //           },
  //         });
  //       } else {
  //         setForms()
  //       }
  //       // form.setFieldsValue({ orgId: item.org_id });
  //     }
  //     setLoadingState(false)
  //   } else {
  //     Modal.warning({
  //       title: '错误信息',
  //       centered: true,
  //       content: result.data.datas.msg,
  //     })
  //   }
  // };

  // useEffect(() => {
  //   let isSubscribed = true;
  //   if (isSubscribed) {
  //     fetch()
  //   }
  //   return () => isSubscribed = false;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  useEffect(() => {
    axios.post(baseUrl + 'index/index/get_info_list', {
      token: user.token,
      member_id: member_id
    }).then(res => {
      if (res.data.code === 20000) {
        // let areaName = res.data.datas.area_name;
        let reslist = res.data.datas.list
        setNation(reslist['1'])
        setPoliticalOutlook(reslist['2'])
        setClassOfPositions(reslist['3'])
        setIdentity(reslist['4'])
        setEducationalLevel(reslist['6'])
        setDeputyToNpc(reslist['7'])
        setMemberOfTheCppcc(reslist['8'])
        setRepresentativesOfTheCpc(reslist['9'])
        setMemberOfThePartyCommittee(reslist['10'])
        setDeptClassification(reslist['11'])
        setAuthorityOfCadreManagement(reslist['12'])
        setObjectOfSupervision(reslist['14'])
        setKinsfolk(reslist['13'])
        setOrgList(res.data.datas.org_list)
        return res.data.datas.member
      }
    }).then(m => {
      if (member_id == 0 || !!member) {
        // 添加新成员
        if (!!member) {
          //使用从work导入的数据
          m = { ...member, area_name: user.areaName, idcard: id_card }
        }
        if (!!member && member.base64_img) {
          let file = base64ConvertFile(member.base64_img, 'file');
          let formData = new FormData();
          formData.append('token', user.token);
          formData.append('file', file)
          axios.post(global.uploadUrl, formData, {
            headers: { "Content-Type": "multipart/form-data" }
          }).then(res => {
            uploadComplet(res.data)
          })
        }
      }
      // 请求数据
      console.log(m)
      setForms(m)
      setImg(m)
    })
  }, [member]);
  const base64ConvertFile = function (urlData, filename) { // 64转file
    if (typeof urlData != 'string') {
      this.$toast("urlData不是字符串")
      return;
    }
    var arr = urlData.split(',')
    var type = arr[0].match(/:(.*?);/)[1]
    var fileExt = type.split('/')[1]
    var bstr = atob(arr[1])
    var n = bstr.length
    var u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], 'filename.' + fileExt, {
      type: type
    });
  }
  const setImg = (item) => {
    setImageUrl(item.img_url)
    setPhoto(item.photo)
  }
  const setForms = (item) => {
    let a = form.getFieldsValue();
    console.log(a)
    let birthday = null;
    if (item.birthday) {
      birthday = moment(item.birthday, 'YYYY-MM-DD')
    }

    let starting_work_date = null;
    if (item.starting_work_date) {
      starting_work_date = moment(item.starting_work_date, 'YYYY-MM')
    }


    let politicalPartyTime = null;
    if (item.political_party_time) {
      politicalPartyTime = moment(item.political_party_time, 'YYYY-MM')
    }

    let resume = item.resume;
    if (resume) {
      resume = resume.replace(/<br\/>/g, '\r\n');
    }

    if (!!item.relationship) {
      console.log(item.relationship)
      let rs = (typeof item.relationship == 'object') ? item.relationship : JSON.parse(item.relationship);
      // for (let i in rs) {
      //   let birth = rs[i]['birth'];
      //   if (!!birth) rs[i]['birth'] = moment(birth, 'YYYY-MM-DD')
      // }
      form.setFieldsValue({
        relationship: rs
      })
    }

    form.setFieldsValue({
      memberName: item.member_name,
      sex: item.sex,
      birthday: birthday,
      nation: item.nation,
      nativePlace: item.native_place,
      startingWorkDate: starting_work_date,
      politicalOutlook: item.political_outlook,
      politicalPartyTime: politicalPartyTime,
      classOfPositions: item.class_of_positions,
      duties: item.duties,
      unitClass: item.unit_class,
      identity: item.identity,
      areaName: item.area_name,
      education: item.education,
      school: item.school,
      deputyToNpc: item.deputy_to_npc,
      memberOfTheCppcc: item.member_of_the_cppcc,
      representativesOfTheCpc: item.representatives_of_the_cpc,
      memberOfThePartyCommittee: item.member_of_the_party_committee,
      deptClassification: item.dept_classification,
      deptName: item.dept_name,
      authorityOfCadreManagement: item.authority_of_cadre_management,
      objectOfSupervision: item.object_of_supervision,
      passport: item.passport,
      eepToHkAndMacau: item.eep_to_hk_and_macau,
      currentDuties: item.current_duties,
      idCard: item.idcard,
      contact: item.contact,
      address: item.address,
      resume: resume,
      // placeOfWork: item.org_name,
      orgId: item.org_id,
    })
  }
  const uploadChange = info => {
    setImageUrl(null)
    setPhoto(null)
    if (info.file.status === 'uploading') {
      setUploadLoading(true)
      return
    }
    if (info.file.status === 'done') {
      setUploadLoading(false)
      uploadComplet(info.file.response)
    }
  }
  const uploadComplet = (res) => {
    if (res.status === 'success') {
      setImageUrl(res.full_url)
      setPhoto(res.img_url)
    } else {
      notification['error']({
        message: '上传失败',
        description:
          res.msg,
      });
    }
  }
  const deptChange = () => {
    setDeptNameInput(false)
  }

  const returnHandler = () => {
    history.goBack()
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>照片</div>
    </div>
  );

  const save = (fieldsValue) => {
    const values = {
      ...fieldsValue,
      'birthday': fieldsValue['birthday'].format('YYYY-MM-DD'),
      // 'startingWorkDate': fieldsValue['startingWorkDate'].format('YYYY-MM'),
    }
    let startingWorkDate = null
    if (fieldsValue['startingWorkDate']) {
      startingWorkDate = fieldsValue['startingWorkDate'].format('YYYY-MM')
    }
    let politicalPartyTime = null
    if (fieldsValue['politicalPartyTime']) {
      console.log(fieldsValue['politicalPartyTime'])
      politicalPartyTime = fieldsValue['politicalPartyTime'].format('YYYY-MM')
    }
    let relationship = null
    if (fieldsValue['relationship']) {
      relationship = fieldsValue['relationship'];
      relationship = JSON.stringify(relationship)
    }

    let resume = values.resume;
    if (resume) {
      resume = resume.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, ' ');
    }
    axios.post(baseUrl + 'index/member/save', {
      member_id: member_id,
      member_name: values.memberName,
      sex: values.sex,
      birthday: values.birthday,
      nation: values.nation,
      native_place: values.nativePlace,
      starting_work_date: startingWorkDate,
      political_outlook: values.politicalOutlook,
      political_party_time: politicalPartyTime,
      class_of_positions: values.classOfPositions,
      duties: values.duties,
      unit_class: values.unitClass,
      identity: values.identity,
      education: values.education,
      school: values.school,
      deputy_to_npc: values.deputyToNpc,
      member_of_the_cppcc: values.memberOfTheCppcc,
      representatives_of_the_cpc: values.representativesOfTheCpc,
      member_of_the_party_committee: values.memberOfThePartyCommittee,
      dept_classification: values.deptClassification,
      dept_name: values.deptName,
      authority_of_cadre_management: values.authorityOfCadreManagement,
      object_of_supervision: values.objectOfSupervision,
      passport: values.passport,
      eep_to_hk_and_macau: values.eepToHkAndMacau,
      current_duties: values.currentDuties,
      idcard: values.idCard,
      contact: values.contact,
      address: values.address,
      resume: resume,
      relationship: JSON.stringify(relationship),
      photo: photo,
      // place_of_work: values.placeOfWork,
      org_id: values.orgId,
      token: user.token
    })
      .then(function (response) {
        setLoadingState(false)
        if (response.data.code === 20000) {
          if (member_id === 0) {
            history.push({
              pathname: '/add_step3',
            })
          } else {
            Modal.success({
              centered: true,
              content: '信息提交成功',
              onOk: successHandler
            })
          }
        } else {
          Modal.warning({
            title: '错误信息',
            centered: true,
            content: response.data.datas.msg,
          })
        }
      })
  }

  const successHandler = () => {
    // window.location.reload()
    history.goBack()
  }
  const relations = () => {
    var arr = Array.from({ length: 10 }, (v, k) => k);
    return arr.map((i) => {
      return (
        <Row>
          <Col span={4} style={{ padding: "0 15px" }}>
            <Form.Item name={['relationship', i, 'name']} style={{ width: '100%' }}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={4} style={{ padding: "0 15px" }}>
            <Form.Item
              name={['relationship', i, 'kinsfolk']} style={{ width: '100%' }}
              rules={[{ type: 'enum', message: '请选在下拉列表中选择', enum: transArr(kinsfolkData, 'name') }]}
            >
              <Select className="input-width" placeholder="-请选择-" allowClear={true}>
                {
                  kinsfolkData.map((item) => (
                    <Option key={item.id} value={item.name}>{item.name}</Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={4} style={{ padding: "0 15px" }}>
            <Form.Item
              name={['relationship', i, 'politicalOutlook']}
              rules={[{ type: 'enum', message: '请选在下拉列表中选择', enum: transArr(politicalOutlookData, 'name') }]}
            >
              <Select className="input-width" placeholder="-请选择-" allowClear={true}>
                {
                  politicalOutlookData.map((item) => (
                    <Option key={item.id} value={item.name}>{item.name}</Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={0} style={{ padding: "0 15px" }}>
            <Form.Item
              name={['relationship', i, 'age']}
              style={{ width: '100%' }}
              rules={[{ type: 'integer', min: 0, max: 150, message: '年龄为0-150之间的数字' }]}>
              <Input style={{ width: '100%' }} type="hidden" />
            </Form.Item>
          </Col>
          <Col span={12} style={{ padding: "0 15px" }}>
            <Form.Item name={['relationship', i, 'work']} style={{ width: '100%' }}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      )
    })
  }
  const submitHandler = (fieldsValue) => {
    confirm({
      title: '请仔细核对各项信息，确认提交？',
      icon: <ExclamationCircleOutlined />,
      content: '',
      centered: true,
      okText: '确定',
      cancelText: '取消',
      onOk() {
        save(fieldsValue)
      },
      onCancel() { },
    });
  }
  const transArr = (arr, key) => {
    let names = [];
    for (let i in arr) {
      names[i] = arr[i][key];
    }
    return names
  }
  return (
    <div className="form-wrap">
      <Spin tip="Loading..." spinning={formLoading} size="large" >
        <Form form={form} onFinish={submitHandler} >
          <Row>
            <Col span={24}>
              <Row className="height60">
                <Col span={2} className="form-label" >姓名</Col>
                <Col span={5}>
                  <Form.Item
                    name="memberName"
                    rules={[{ required: true, max: 50, message: '请输入姓名, 不超过50个字符' }]}
                  >
                    <Input className="input-width" placeholder="请输入姓名" />
                  </Form.Item>
                </Col>
                <Col span={2} className="form-label">性别</Col>
                <Col span={5}>
                  <Form.Item name="sex" initialValue={'1'} rules={[{ required: true, message: '请选择性别' }]}>
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value={'1'}>男</Radio.Button >
                      <Radio.Button value={'2'}>女</Radio.Button >
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={2} className="form-label">出生日期</Col>
                <Col span={5}>
                  <Form.Item name="birthday" rules={[{ required: true, message: '请选择出生日期' }]}>
                    <DatePicker locale={locale} className="input-width" placeholder="-请选择-" />
                  </Form.Item>
                </Col>
                <Col span={3} style={{ paddingLeft: 15 }} >
                  <Upload
                    name="file"
                    listType="picture-card"
                    showUploadList={false}
                    action={global.uploadUrl}
                    onChange={uploadChange}
                    // beforeUpload={file => { console.log(file) }}
                    method='POST'
                    data={{ token: user.token }}
                    className="avatar-uploader"
                  >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '147.5px', height: '206.5px' }} /> : uploadButton}
                  </Upload>
                </Col>
              </Row>
              <Row className="height60">
                <Col span={2} className="form-label">民族</Col>
                <Col span={5} >
                  <Form.Item name="nation" rules={[{ required: true, message: '请选择民族' }]}>
                    <Select className="input-width" placeholder="-请选择-" allowClear={true}>
                      {
                        nationData.map((item) => (
                          <Option key={item.id} value={item.name}>{item.name}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2} className="form-label">籍贯</Col>
                <Col span={5} >
                  <Form.Item
                    name="nativePlace" rules={[{ required: true, max: 50, message: '请输入籍贯,不超过50个字符' }]}
                  >
                    <Input className="input-width" placeholder="请输入籍贯" />
                  </Form.Item>
                </Col>
                <Col span={2} className="form-label">参加工作时间</Col>
                <Col span={5} >
                  <Form.Item name="startingWorkDate">
                    <DatePicker locale={locale} picker="month" className="input-width" placeholder="-请选择-" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="height60">
                <Col span={2} className="form-label">政治面貌</Col>
                <Col span={5} >
                  <Form.Item name="politicalOutlook" rules={[{ required: true, type: 'enum', message: '请选在下拉列表中选择', enum: transArr(politicalOutlookData, 'name') }]}>
                    <Select className="input-width" placeholder="-请选择-" allowClear={true}>
                      {
                        politicalOutlookData.map((item) => (
                          <Option key={item.id} value={item.name}>{item.name}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2} className="form-label">入党时间</Col>
                <Col span={5} >
                  <Form.Item name="politicalPartyTime" >
                    <DatePicker locale={locale} picker="month" className="input-width" placeholder="-请选择-" />
                  </Form.Item>
                </Col>
                <Col span={2} className="form-label">职级</Col>
                <Col span={5} >
                  <Form.Item name="classOfPositions" rules={[{ required: true, type: 'enum', message: '请选在下拉列表中选择', enum: transArr(classOfPositionsData, 'name') }]}>
                    <Select className="input-width" placeholder="-请选择-" allowClear={true}>
                      {
                        classOfPositionsData.map((item) => (
                          <Option key={item.id} value={item.name}>{item.name}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="height60">
                <Col span={2} className="form-label">社会职务</Col>
                <Col span={5} >
                  <Form.Item name="duties" rules={[{ max: 50, message: '不能超过50个字符' }]}>
                    <Input className="input-width" placeholder="请输入社会职务" />
                  </Form.Item>
                </Col>
                <Col span={2} className="form-label">单位类别</Col>
                <Col span={5} >
                  <Form.Item name="unitClass" rules={[{ max: 50, message: '不能超过50个字符' }]}>
                    <Input className="input-width" placeholder="请输入单位类别" />
                  </Form.Item>
                </Col>
                <Col span={2} className="form-label">身份类别</Col>
                <Col span={5} >
                  <Form.Item name="identity" rules={[{ required: true, type: 'enum', message: '请选在下拉列表中选择', enum: transArr(identityData, 'name') }]}>
                    <Select className="input-width" placeholder="-请选择-" allowClear={true}>
                      {
                        identityData.map((item) => (
                          <Option key={item.id} value={item.name}>{item.name}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row className="height60">
                <Col span={2} className="form-label">所在区域</Col>
                <Col span={5}>
                  <Form.Item>
                    <Form.Item name="areaName" >
                      <Input disabled />
                    </Form.Item>
                  </Form.Item>
                </Col>
                <Col span={2} className="form-label">文化程度</Col>
                <Col span={5}>
                  <Form.Item name="education" rules={[{ required: true, type: 'enum', message: '请选在下拉列表中选择', enum: transArr(educationalLevelData, 'name') }]}>
                    <Select placeholder="-请选择-" allowClear={true}>
                      {
                        educationalLevelData.map((item) => (
                          <Option key={item.id} value={item.name}>{item.name}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2} className="form-label">毕业院校</Col>
                <Col span={8}>
                  <Form.Item
                    name="school"
                    rules={[{ required: true, max: 50, message: '请输入毕业院校,不超过50个字符' }]}
                  >
                    <Input placeholder="请输入毕业院校" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row className="height60">
                <Col span={2} className="form-label">人大代表</Col>
                <Col span={5}>
                  <Form.Item name="deputyToNpc" rules={[{ required: true, type: 'enum', message: '请选在下拉列表中选择', enum: transArr(deputyToNpcData, 'name') }]}>
                    <Select placeholder="-请选择-" allowClear={true}>
                      {
                        deputyToNpcData.map((item) => (
                          <Option key={item.id} value={item.name}>{item.name}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2} className="form-label">政协委员</Col>
                <Col span={5}>
                  <Form.Item name="memberOfTheCppcc" rules={[{ required: true, type: 'enum', message: '请选在下拉列表中选择', enum: transArr(memberOfTheCppccData, 'name') }]}>
                    <Select placeholder="-请选择-" allowClear={true}>
                      {
                        memberOfTheCppccData.map((item) => (
                          <Option key={item.id} value={item.name}>{item.name}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2} className="form-label">中共党代表</Col>
                <Col span={8}>
                  <Form.Item name="representativesOfTheCpc" rules={[{ required: true, type: 'enum', message: '请选在下拉列表中选择', enum: transArr(representativesOfTheCpcData, 'name') }]}>
                    <Select placeholder="-请选择-" allowClear={true}>
                      {
                        representativesOfTheCpcData.map((item) => (
                          <Option key={item.id} value={item.name}>{item.name}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row className="height60">
                <Col span={2} className="form-label">党委委员</Col>
                <Col span={5}>
                  <Form.Item name="memberOfThePartyCommittee" rules={[{ required: true, type: 'enum', message: '请选在下拉列表中选择', enum: transArr(memberOfThePartyCommitteeData, 'name') }]}>
                    <Select placeholder="-请选择-" allowClear={true}>
                      {
                        memberOfThePartyCommitteeData.map((item) => (
                          <Option key={item.id} value={item.name}>{item.name}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2} className="form-label">部门分类</Col>
                <Col span={5}>
                  <Form.Item name="deptClassification"
                    rules={[{ required: true, type: 'enum', message: '请选在下拉列表中选择', enum: transArr(deptClassificationData, 'name') }]}
                    style={{ display: 'inline-block', width: '50%' }}>
                    <Select placeholder="-请选择-" onChange={deptChange} allowClear={true}>
                      {
                        deptClassificationData.map((item) => (
                          <Option key={item.id} value={item.name}>{item.name}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="deptName"
                    style={{ display: 'inline-block', paddingLeft: '8px', width: '50%' }}
                    rules={[{ max: 50, message: '不能超过50个字符' }]}
                  >
                    <Input disabled={deptNameInput} />
                  </Form.Item>
                </Col>
                <Col span={2} className="form-label">护照</Col>
                <Col span={8}>
                  <Form.Item name="passport" rules={[{ max: 50, message: '不能超过50个字符' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row className="height60">
                <Col span={2} className="form-label">干部管理权限</Col>
                <Col span={5}>
                  <Form.Item name="authorityOfCadreManagement" rules={[{ required: true, type: 'enum', message: '请选在下拉列表中选择', enum: transArr(authorityOfCadreManagementData, 'name') }]}>
                    <Select placeholder="-请选择-" allowClear={true}>
                      {
                        authorityOfCadreManagementData.map((item) => (
                          <Option key={item.id} value={item.name}>{item.name}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2} className="form-label">国家监察对象</Col>
                <Col span={5}>
                  <Form.Item name="objectOfSupervision"
                    rules={[{ required: true, type: 'enum', message: '请选在下拉列表中选择', enum: transArr(objectOfSupervisionData, 'name') }]}>
                    <Select placeholder="-请选择-" allowClear={true}>
                      {
                        objectOfSupervisionData.map((item) => (
                          <Option key={item.id} value={item.name}>{item.name}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2} className="form-label">港澳通行证</Col>
                <Col span={8}>
                  <Form.Item name="eepToHkAndMacau" rules={[{ max: 50, message: '不能超过50个字符' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row className="height60">
                <Col span={2} className="form-label">现任职务</Col>
                <Col span={22}>
                  <Form.Item
                    name="currentDuties"
                    style={{ display: 'inline-block', width: '100%' }}
                    rules={[{ max: 50, message: '不能超过50个字符' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row className="height60">
                <Col span={2} className="form-label">身份证号</Col>
                <Col span={10}>
                  <Form.Item
                    name="idCard" style={{ display: 'inline-block', width: '100%' }}
                    rules={[{ required: true, message: '请输入身份证号' }]}
                    initialValue={210702}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={2} className="form-label">联系方式</Col>
                <Col span={10}>
                  <Form.Item
                    name="contact" style={{ display: 'inline-block', width: '100%' }}
                    rules={[
                      { required: true, message: '请输入联系方式', pattern: '' },
                      {
                        pattern: /\d{3}\d{8}|\d{4}[-|\s]?\d{7,8}/,
                        message: '区号+固定电话/11位手机号',
                      },

                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row className="height60">
                <Col span={2} className="form-label">家庭住址</Col>
                <Col span={22}>
                  <Form.Item
                    name="address" style={{ width: '100%' }}
                    rules={[{ required: true, max: 50, message: '家庭住址不超过50字' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row>
                <Col span={2} className="form-label">简历</Col>
                <Col span={22}>
                  <Form.Item
                    name="resume" style={{ width: '100%' }}
                  >
                    <Input.TextArea rows={8} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row key={0}>
                <Col span={2} className="form-label">家庭主要成员及重要社会关系</Col>
                <Col span={22}>
                  <Row style={{ marginBottom: "15px" }}>
                    <Col span={4} style={{ textAlign: "center" }}>姓名</Col>
                    <Col span={4} style={{ textAlign: "center" }}>称谓</Col>
                    <Col span={4} style={{ textAlign: "center" }}>政治面貌</Col>
                    <Col span={0} style={{ textAlign: "center" }}>出生时间</Col>
                    <Col span={12} style={{ textAlign: "center" }}>工作单位及职务</Col>
                  </Row>
                  {relations()}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row key={4}>
            <Col span={24}>
              <Row className="height60">
                <Col span={2} className="form-label">所属单位</Col>
                <Col span={22} >
                  <Form.Item name="orgId" rules={[{ required: true, type: 'enum', message: '请选在下拉列表中选择', enum: transArr(orgListData, 'id') }]}>
                    <Select
                      disabled={changeWorkPlace}
                      showSearch
                      placeholder="-请选择-"
                    >
                      {
                        orgListData.map((item) => (
                          <Option key={item.id} value={item.id}>{item.name}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Space size="large">
                <Button type="primary" htmlType="submit" >提交修改</Button>
                <Button htmlType="button" onClick={returnHandler}>返回</Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  )
}

export default ArchivesForm