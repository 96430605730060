import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { message, Button, Breadcrumb, Table, Space, Input, Form, Select, Modal, Radio, Tag, Transfer } from 'antd';
import { EditOutlined, SearchOutlined, ContainerOutlined, ExportOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import Header from '../../components/header';
import Sider from '../../components/sider';
import global from '../../model/config'

import axios from "axios"
import XLSX from 'xlsx';

import storage from '../../model/storage'
import './list.css'

const field_name = {
  member_name: '姓名',
  sex: '性别',
  birthday: '出生日期',
  nation: '民族',
  native_place: '籍贯',
  starting_work_date: '参加工作时间',
  political_outlook: '政治面貌',
  political_party_time: '入党时间',
  class_of_positions: '职级',
  duties: '社会职务',
  unit_class: '单位类别',
  identity: '身份类型',
  area_name: '所在区域',
  education: '文化程度',
  school: '毕业院校',
  deputy_to_npc: '人大代表',
  member_of_the_cppcc: '政协委员',
  representatives_of_the_cpc: '中共党代表',
  member_of_the_party_committee: '党委委员',
  dept_classification: '部门分类',
  dept_name: '部门名称',
  authority_of_cadre_management: '干部管理权限',
  object_of_supervision: '国家监察对象',
  passport: '护照',
  eep_to_hk_and_macau: '港澳通行证',
  current_duties: '现任职务',
  idcard: '身份证号',
  contact: '联系方式',
  address: '家庭住址',
  // place_of_work: '所属单位',
  org: '所属单位'
};
const Trans = (props) => {
  const { callback } = props;
  const mockData = [];
  for (let i in field_name) {
    mockData.push({ key: i, title: field_name[i], descript: '' });
  }
  // const initialTargetKeys = mockData.map(item => item.key);
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const onChange = (nextTargetKeys, direction, moveKeys) => {
    let target = nextTargetKeys
    if (direction === 'right') {
      target = [...targetKeys, ...moveKeys]
    }
    setTargetKeys(target);
    callback(target)
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    // console.log([...sourceSelectedKeys, ...targetSelectedKeys])
    // console.log(targetSelectedKeys)
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onScroll = (direction, e) => {
    // console.log('direction:', direction);
    // console.log('target:', e.target);
  };

  return (
    <Transfer
      dataSource={mockData}
      titles={['', '导出字段']}
      targetKeys={targetKeys}
      selectedKeys={selectedKeys}
      locale={{ itemUnit: '项', itemsUnit: '项', searchPlaceholder: '请输入搜索内容' }}
      onChange={onChange}
      onSelectChange={onSelectChange}
      onScroll={onScroll}
      oneWay
      render={item => item.title}
    />
  );
}
const MemberList = () => {
  const [test, setTest] = useState(1);
  const columns = [
    {
      title: '姓名',
      dataIndex: 'member_name',
      key: 'member_name',
    },
    {
      title: '身份证号',
      dataIndex: 'idcard',
      key: 'idcard',
    },
    {
      title: '性别',
      dataIndex: 'sex',
      key: 'sex',
    },
    {
      title: '工作单位',
      dataIndex: 'org',
      key: 'org',
    },
    {
      title: '文化程度',
      dataIndex: 'education',
      key: 'education',
    },
    {
      title: '政治面貌',
      dataIndex: 'political_outlook',
      key: 'political_outlook',
    },
    {
      title: '',
      key: 'work_status',
      dataIndex: 'work_status',
      render: (text, record) => {
        let color = 'green';
        let txt = '在职';
        if (record.work_status === 3) {
          color = 'volcano';
          txt = '死亡';
        }
        return (
          <Tag color={color}>
            {txt}
          </Tag>
        )
      }
    },
    {
      title: '',
      key: 'action',
      render: (record) => {
        let btnDisabled = false;
        if (record.work_status === 3) {
          btnDisabled = true
        }
        return (
          <Space size="middle">
            <Button type="link" icon={<EditOutlined />} onClick={() => editHandler(record.id)}>编辑</Button>
            <Button type="link" disabled={btnDisabled} icon={<ExportOutlined />} onClick={() => exportHandler(record.id)}>转出/删除</Button>
            <Button type="link" icon={<ContainerOutlined />} onClick={() => infoHandler(record.id)}>详情</Button>
          </Space>
        )
      }
    },
  ];

  const { confirm, warning } = Modal
  const member = JSON.parse(sessionStorage.getItem("member"))

  const [pagination, setPagination] = useState(member || { current: 1, pageSize: 10 });
  const [loading, setLoading] = useState(false);
  const [memberList, setMemberListData] = useState([]);
  const [orgListData, setOrgList] = useState([]);
  const [searchData, setSearchData] = useState([]);

  const [searchForm] = Form.useForm();
  const { Option } = Select;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [workStatus, setWorkStatus] = useState(2);
  const [memberId, setMemberId] = useState([]);
  // const [selected, setSelected] = useState([]);

  storage.set('selectedKeys', 'archivesList')

  let history = useHistory();
  let baseUrl = global.baseUrl;
  let user = storage.get('user')

  const editHandler = memberId => {
    history.push({
      pathname: '/edit_member',
      state: {
        memberId: memberId
      },
    })
  }

  const exportHandler = memberId => {
    setIsModalVisible(true)
    setMemberId([memberId])
  }

  const infoHandler = memberId => {
    history.push({
      pathname: '/member_info',
      state: {
        memberId: memberId
      },
    })
  }

  const handleTableChange = (pagination) => {
    fetch(pagination);
  };

  const fetch = async (params) => {
    setLoading(true)
    const result = await axios.post(baseUrl + 'index/member/index', {
      curr_page: params.current,
      limit: params.pageSize,
      token: user.token,
      org_name: searchData.orgName,
      member_name: searchData.memberName,
      idcard: searchData.idCard
    })
    setLoading(false)

    if (result.data.code === 20000) {
      setMemberListData(result.data.datas.list);
      console.log(result.data.datas);
      console.log(pagination)
      setPagination({
        ...params.pagination,
        current: result.data.datas.curr_page,
        pageSize: params.pageSize,
        total: result.data.datas.total,
      })

      let member = JSON.stringify(params)
      sessionStorage.setItem("member", member);
    } else {
      Modal.warning({
        title: '错误信息',
        centered: true,
        content: result.data.datas.msg,
      })
    }
  }

  const getOrgList = async () => {
    const result = await axios.post(baseUrl + 'index/organization/get_org_list', {
      token: user.token
    })
    if (result.data.code === 20000) {
      setOrgList(result.data.datas.org_list)
    } else {
      Modal.warning({
        title: '错误信息',
        centered: true,
        content: result.data.datas.msg,
      })
    }
  }

  useEffect(() => {
    // 更新搜索条件
    console.log(searchData)
    let isSubscribed = true;
    if (isSubscribed) {
      // setPagination({ current: 1, pageSize: 10 });
      fetch(pagination)
      // fetch({ pagination: { current: 1, pageSize: 10 } })
      getOrgList()
    }
    // 清除选取要编辑的成员checkbox?好像没用?
    setMemberId([]);
    return () => isSubscribed = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);

  const onSearch = (values) => {
    let query = []
    query.orgName = values.orgName
    query.memberName = values.memberName
    query.idCard = values.idCard
    setSearchData(query)
    setPagination({ current: 1, pageSize: 10 })
  }

  const radioStyle = {
    display: 'block',
    height: '50px',
    lineHeight: '50px',
  };

  //保存人员转出结果
  const exportFormhandleOk = () => {
    let ids, path;
    if (memberId.length == 1) {
      ids = memberId[0]
      path = 'index/member/move_out'
    } else {
      ids = memberId
      path = 'index/member/move_out_mult'
    }
    confirm({
      title: '确定将此人转出?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      centered: true,
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        axios.post(baseUrl + path, {
          member_id: ids,
          token: user.token,
          work_status: workStatus
        })
          .then(function (response) {
            if (response.data.code === 20000) {
              fetch(pagination)
            } else {
              Modal.warning({
                title: '错误信息',
                centered: true,
                content: response.data.datas.msg,
              })
            }
          })
      },
      onCancel() { },
    });
    setIsModalVisible(false)
  };

  const exportFormhandleCancel = () => {
    setIsModalVisible(false)
  };

  const workStatusChange = e => {
    setWorkStatus(e.target.value)
  };
  const delMult = () => {
    setIsModalVisible(true)
  }
  const sheet2blob = (sheet, sheetName) => {
    // 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
    sheetName = sheetName || 'sheet1';
    var workbook = {
      SheetNames: [sheetName],
      Sheets: {}
    };
    workbook.Sheets[sheetName] = sheet;
    // 生成excel的配置项
    var wopts = {
      bookType: 'xlsx', // 要生成的文件类型
      bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
      type: 'binary'
    };
    var wbout = XLSX.write(workbook, wopts);
    var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    // 字符串转ArrayBuffer
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }
    return blob;
  }
  const openDownloadDialog = (url, saveName) => {
    if (typeof url == 'object' && url instanceof Blob) {
      url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) event = new MouseEvent('click');
    else {
      event = document.createEvent('MouseEvents');
      event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    }
    aLink.dispatchEvent(event);
  }
  const downloading = (fields) => {
    console.log(fields)
    const key = 'updatable';
    let opt = searchForm.getFieldsValue();
    let options = {}
    if (!!opt['memberName']) options['member_name'] = opt['memberName'];
    if (!!opt['idCard']) options['idcard'] = opt['idCard'];
    if (!!opt['orgName']) options['place_of_work'] = opt['orgName'];
    options['work_status'] = [1, 3];
    axios({
      method: 'POST',
      url: baseUrl + 'v1/member/index',
      data: {
        token: user.token,
        fields: fields,
        options: options,
        // file_type: 'excel'
      },
      // timeout: 300000,
      // responseType: 'blob'
    }).then(res => {
      const { data, headers } = res;
      if (res.status === 200) {
        // const fileName = headers['content-disposition'].replace(/\w+;filename=(.*)/, '$1')
        // const blob = new Blob([data], { type: headers['content-type'] })
        // openDownloadDialog(blob, fileName);
        let f = [];
        for (let k of Object.values(fields)) {
          f.push(field_name[k])
        }
        let aoa = [f];
        for (let i in data) {
          let arr = [];
          for (let key of Object.values(fields)) {
            // if (key === 'sex') {
            //   arr.push(data[i]['sex'] === '1' ? '男' : '女')
            // } else {
            arr.push(data[i][key])
            // }
          }
          aoa.push(arr);
        }
        var sheet = XLSX.utils.aoa_to_sheet(aoa);
        openDownloadDialog(sheet2blob(sheet), '导出.xlsx');
        message.success({ content: '下载成功!', key, duration: 2 });
      } else {
        message.error({ content: '下载失败!', key, duration: 1 });
      }
    }).catch(function (error) {
      message.error({ content: '下载失败!', key, duration: 1 });
      warning({
        content: '下载失败'
      })
      console.log(error);
    });
    message.loading({
      content: '文件下载中...',
      key,
      className: 'large',
      duration: 0
    });
  }
  // 选择导出的字段
  const setKeys = () => {
    let fields = [];
    const setFields = (val) => {
      fields = val
    }
    confirm({
      title: '导出内容',
      icon: <ExclamationCircleOutlined />,
      content: <Trans callback={setFields} />,
      width: 550,
      okText: '导出Excel',
      cancelText: '取消',
      centered: true,
      onOk() {
        if (fields.length > 0) {
          downloading(fields)
          // if (memberId.length > 0) outExcel(fields)
        } else {
          message.error('请选择要导出的内容');
          // return false;
        }
      },
      onCancel() {

      }
    })
  }
  // 是否导出全部数据
  const outExcel = (keys) => {
    let num = memberId.length;
    confirm({
      title: '提示信息',
      icon: <ExclamationCircleOutlined />,
      content: '是否导出选中的数据',
      okText: '是，导出选中的' + num + '数据',
      cancelText: '否，导出全部数据',
      centered: true,
      onOk() {
        console.log(num)
      },
      onCancel() {

      }
    })
  }
  const transKey = (csv) => {
    let arr = csv.split('\n');
    let key = arr[0];
    console.log(arr)
    // let keys = arr1[0].split(',');
    for (let i in field_name) {
      key = key.replace(field_name[i], i);
    }
    return key.split(',')
    // arr[0] = key;
    // for (let j in arr) {
    //   arr[j] = arr[j].split(',')
    // }
    // let sheet = XLSX.utils.aoa_to_sheet(arr);
    // let json = XLSX.utils.make_json(sheet);
    // return json
  }
  const InputData = () => {
    Modal.info({
      title: '注意',
      centered: true,
      content: '导入数据文件格式为Excel，尽量使用导出的Excel文件格式，并且必须包含身份证信息。',
      onOk: () => {
        let dom = document.getElementById('inputExcel')
        if (!dom) {
          dom = document.createElement('input');
          dom.type = 'file';
          dom.id = 'inputExcel';
          document.body.appendChild(dom);
        } else {
          dom.value = null;
        }
        dom.onchange = function (e) {
          let files = e.target.files;
          if (files.length == 0) return;
          let file = files[0];
          let reader = new FileReader();
          reader.readAsBinaryString(file);
          reader.onload = function (e) {
            let data = e.target.result;
            let workbook = XLSX.read(data, { type: 'binary' })
            let sheetNames = workbook.SheetNames;
            let worksheet = workbook.Sheets[sheetNames[0]];
            let csv = XLSX.utils.make_csv(worksheet)
            let keys = transKey(csv);
            let json = XLSX.utils.sheet_to_json(worksheet, { header: keys, defval: '' });
            // let aoa = XLSX.utils.aoa(worksheet);
            // console.log(transKey(csv))
            json.shift()
            multiSave(json)
          }
        }
        dom.click();
      }
    });
  }
  const multiSave = async (list) => {
    const result = await axios.post(baseUrl + 'v1/member/save', {
      token: user.token,
      list: list
    }).then(res => {
      if (res.status === 200) {
        let err = [], message = '数据修改成功';
        for (let i in res.data) {
          if (typeof res.data[i] === 'number') err.push(res.data[i]);
        }
        message = '成功修改' + (res.data.length - err.length) + '条数据。';
        if (err.length > 0) message += '第' + err.join(',') + '条数据修改失败，请在提交的数据中包含idcard字段。或使用系统导出的文档进行修改，再上传更新！';
        warning({
          title: '数据提交完成',
          centered: true,
          content: message
        })
      }
    })
  }
  return (
    <div className="wrap">
      <Header />
      <Sider />
      <div className="container">
        <div className="main-content">
          <Breadcrumb>
            <Breadcrumb.Item>首页</Breadcrumb.Item>
            <Breadcrumb.Item>档案管理</Breadcrumb.Item>
            <Breadcrumb.Item>档案列表</Breadcrumb.Item>
          </Breadcrumb>

          <div className="member-list">
            <div className="search-member-area">
              <Form form={searchForm} name="horizontal_login" layout="inline" onFinish={onSearch}>
                <Form.Item name="memberName">
                  <Input placeholder="姓名" />
                </Form.Item>
                <Form.Item name="idCard">
                  <Input
                    placeholder="身份证号"
                  />
                </Form.Item>
                <Form.Item name="orgName" >
                  <Select allowClear={true} style={{ width: '200px' }}
                    showSearch
                    placeholder="-工作单位-"
                  >
                    {
                      orgListData.map((item) => (
                        <Option key={item.id} value={item.name}>{item.name}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                    查询
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" onClick={delMult}>批量删除</Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" onClick={setKeys}>数据导出</Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" onClick={InputData}>导入Excel</Button>
                </Form.Item>
              </Form>
            </div>
            <Table
              columns={columns}
              dataSource={memberList}
              pagination={{ ...pagination, defaultCurrent: pagination.current }}
              loading={loading}
              onChange={handleTableChange}
              rowSelection={{
                selectedRowKeys: memberId,
                onChange: (selectedRowKeys) => {
                  setMemberId(selectedRowKeys);
                },
                selection: true,
              }}
              rowKey={item => item.id} />
          </div>
        </div>
      </div>

      <Modal title="选择转出类型"
        visible={isModalVisible}
        destroyOnClose
        onOk={exportFormhandleOk}
        onCancel={exportFormhandleCancel}
        centered={true}
        okText={'确认'}
        cancelText={'取消'}
      >
        <Form
          layout="vertical"
          name="form_in_modal"
        >
          <Form.Item >
            <Radio.Group value={workStatus} onChange={workStatusChange}>
              <Radio style={radioStyle} value={2}>人事调动</Radio>
              <Radio style={radioStyle} value={3}>人员死亡</Radio>
              <Radio style={radioStyle} value={4}>删除信息</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default MemberList