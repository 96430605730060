
import React, { useState } from "react"
import { Form, Input, Button, Modal, Spin } from 'antd';
//import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";

import axios from "axios"
import storage from '../../model/storage'
import global from '../../model/config'

import './index.css'

function Signin() {

  let history = useHistory();
  let baseUrl = global.baseUrl;

  const [loading, setLoadingState] = useState(false);

  const signinHandler = (values) => {
    // storage.set("user", {
    //         "token": "rs.token",
    //         "areaId": 1,
    //         "areaName": 'rs.area_name',
    //         "userName": 'rs.user_name',
    //         "isLogged": true
    //       })
    // console.log('login')
    // history.push('/')

    setLoadingState(true)

    axios.post(baseUrl + 'index/privilege/check_user', {
      user_name: values.userName,
      password: values.password
    })
    .then(function (response) {
      setLoadingState(false)
      if(response.data.code === 20000){
        let rs = response.data.datas.user
        let user = {
          "token": rs.token,
          "areaId": rs.area_id,
          "areaName": rs.area_name,
          "userName": rs.user_name,
          "isLogged": true
        }
        storage.set("user", user)
        history.push('/')
      }else{
        Modal.warning({
          title: '登录错误',
          centered:true,
          content: response.data.datas.msg,
        });
      }
    })
    .catch(function (error) {
      setLoadingState(false)
      console.log(error);
    });
  }
  return (
    <div className='signin-wrap'>
      <div className='signin-container'>
      <Spin tip="Loading..." spinning={loading}  size="large">
        <div className="signin-title">党员监察对象信息管理系统</div>
        <div className="signin-form-area">
          <Form
            className="signin-form"
            onFinish = {signinHandler}>
            <Form.Item
              name="userName"
              rules={[
                {
                  required: true,
                  message: '请输入管理员账号',
                },
              ]}>
              <Input 
                addonBefore="账号"
                placeholder="请输入管理员账号"
                className="username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: '请输入登录密码',
                },
              ]}>
              <Input.Password 
                addonBefore="密码"
                placeholder="请输入登录密码"
                className="password"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" className="btn-signin" size="large" htmlType="submit" block>登录</Button>
            </Form.Item>
          </Form>
        </div>
        </Spin>
      </div>
      <div className="footer"></div>
    </div>
  )
}

export default Signin