import React from 'react';

import { Button, Breadcrumb} from 'antd';
import { useHistory } from "react-router-dom";

import Header from '../../components/header';
import Sider from '../../components/sider';

import storage from '../../model/storage'
import './info.css'

import TableMember from './table-member';

const MemberInfo = (props) => {
  storage.set('selectedKeys', 'archivesList')
  const memberId = props.location.state.memberId
  let history = useHistory()

  const returnHandler = () => {
    history.goBack()
  }

  return (
    <div className="wrap">
      <Header />
      <Sider />
      <div className="container">
        <div className="main-content">
          <Breadcrumb>
            <Breadcrumb.Item>首页</Breadcrumb.Item>
            <Breadcrumb.Item>档案管理</Breadcrumb.Item>
            <Breadcrumb.Item>档案详情</Breadcrumb.Item>
          </Breadcrumb>

          <div className="member-info" id="member-info">
            <TableMember memberId={memberId} />
            <div className='btn-area'>
              <Button type="primary" htmlType="button" size={'large'} href={'print_member/' + memberId} target='_blank' state={memberId} >打印信息</Button>
              <Button htmlType="button" size={'large'} style={{ marginLeft: '15px' }} onClick={returnHandler}>返回</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MemberInfo