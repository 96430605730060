import React, { useState, useEffect } from 'react';

import { Spin, Row, Col, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import axios from "axios"
import storage from '../../model/storage'
import './info.css'
import global from '../../model/config'

const TableMember = (props) => {
  const memberId = props.memberId

  let baseUrl = global.baseUrl
  let user = storage.get('user')

  const [loading, setLoading] = useState(false)

  const [memberName, setMemberName] = useState(null)
  const [sex, setSex] = useState(null)
  const [birthday, setBirthday] = useState(null)
  const [photo, setPhoto] = useState(null)
  const [nation, setNation] = useState(null)
  const [nativePlace, setNativePlace] = useState(null)
  const [startingWorkDate, setStartingWorkDate] = useState(null)
  const [politicalOutlook, setPoliticalOutlook] = useState(null)
  const [politicalPartyTime, setPoliticalPartyTime] = useState(null)
  const [classOfPositions, setClassOfPositions] = useState(null)
  const [duties, setDuties] = useState(null)
  const [unitClass, setUnitClass] = useState(null)
  const [identity, setIdentity] = useState(null)
  const [areaName, setAreaName] = useState(null)
  const [education, setEducation] = useState(null)
  const [school, setSchool] = useState(null)
  const [deputyToNpc, setDeputyToNpc] = useState(null)
  const [memberOfTheCppcc, setMemberOfTheCppcc] = useState(null)
  const [representativesOfTheCpc, setRepresentativesOfTheCpc] = useState(null)
  const [memberOfThePartyCommittee, setMemberOfThePartyCommittee] = useState(null)
  const [deptClassification, setDeptClassification] = useState(null)
  const [deptName, setDeptName] = useState(null)
  const [authorityOfCadreManagement, setAuthorityOfCadreManagement] = useState(null)
  const [objectOfSupervision, setObjectOfSupervision] = useState(null)
  const [passport, setPassport] = useState(null)
  const [eepToHkAndMacau, setEepToHkAndMacau] = useState(null)
  const [currentDuties, setCurrentDuties] = useState(null)
  const [idcard, setIdcard] = useState(null)
  const [contact, setContact] = useState(null)
  const [address, setAddress] = useState(null)
  const [resume, setResume] = useState(null)
  const [relationship, setRelationship] = useState([])
  const [placeOfWork, setPlaceOfWork] = useState(null)

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      setLoading(true)
      getMemberInfo().then(res => {
        if (res.data.code === 20000) {
          let item = res.data.datas.member
          setMemberName(item.member_name)
          setSex(item.sex_text)
          setBirthday(item.birthday)
          setPhoto(item.photo)
          setNation(item.nation)
          setNativePlace(item.native_place)
          setStartingWorkDate(item.starting_work_date)
          setPoliticalOutlook(item.political_outlook)
          setPoliticalPartyTime(item.political_party_time)
          setClassOfPositions(item.class_of_positions)
          setDuties(item.duties)
          setUnitClass(item.unit_class)
          setIdentity(item.identity)
          setAreaName(item.area_name)
          setEducation(item.education)
          setSchool(item.school)
          setDeputyToNpc(item.deputy_to_npc)
          setMemberOfTheCppcc(item.member_of_the_cppcc)
          setRepresentativesOfTheCpc(item.representatives_of_the_cpc)
          setMemberOfThePartyCommittee(item.member_of_the_party_committee)
          setDeptClassification(item.dept_classification)
          setDeptName(item.dept_name)
          setAuthorityOfCadreManagement(item.authority_of_cadre_management)
          setObjectOfSupervision(item.object_of_supervision)
          setPassport(item.passport)
          setEepToHkAndMacau(item.eep_to_hk_and_macau)
          setCurrentDuties(item.current_duties)
          setIdcard(item.idcard)
          setContact(item.contact)
          setAddress(item.address)
          setResume(item.resume)
          setPlaceOfWork(item.org_name)
          if (item.relationship) {
            setRelationship(item.relationship)
          }
          setLoading(false)
        } else {
          Modal.warning({
            title: '错误信息',
            centered: true,
            content: res.data.datas.msg,
          })
          setLoading(false)
        }
      });
    }
    return () => isSubscribed = false;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMemberInfo = async () => {
    const result = await axios.post(baseUrl + 'index/member/get_member_byid', {
      token: user.token,
      member_id: memberId
    })
    return result
  }

  const photoArea = (
    <div style={{ width: '147.5px', height: '206.5px', lineHeight: '206.5px', textAlign: 'center', fontSize: '22px' }}>
      <PlusOutlined />
    </div>
  );

  return (
    <Spin tip="Loading..." spinning={loading} size="large" delay={500}>
      <table id="member-print-info">
        <tbody>
          <tr>
            <td className="txt-right w100">姓名</td>
            <td className="w200">{memberName}</td>
            <td className="txt-right w100">性别</td>
            <td className="w200">{sex}</td>
            <td className="txt-right w100">出生日期</td>
            <td className="w200">{birthday}</td>
            <td rowSpan="4" >
              {photo ? <img src={photo} alt={memberName} /> : photoArea}
            </td>
          </tr>
          <tr>
            <td className="txt-right">民族</td>
            <td>{nation}</td>
            <td className="txt-right">籍贯</td>
            <td>{nativePlace}</td>
            <td className="txt-right">参加工作时间</td>
            <td>{startingWorkDate}</td>
          </tr>
          <tr>
            <td className="txt-right">政治面貌</td>
            <td>{politicalOutlook}</td>
            <td className="txt-right">入党时间</td>
            <td>{politicalPartyTime}</td>
            <td className="txt-right">职级</td>
            <td>{classOfPositions}</td>
          </tr>
          <tr>
            <td className="txt-right">社会职务</td>
            <td>{duties}</td>
            <td className="txt-right">单位类别</td>
            <td>{unitClass}</td>
            <td className="txt-right">身份类型</td>
            <td>{identity}</td>
          </tr>
          <tr>
            <td className="txt-right">所在区域</td>
            <td>{areaName}</td>
            <td className="txt-right">文化程度</td>
            <td>{education}</td>
            <td className="txt-right">毕业院校</td>
            <td colSpan="2">{school}</td>
          </tr>
          <tr>
            <td className="txt-right">人大代表</td>
            <td>{deputyToNpc}</td>
            <td className="txt-right">政协委员</td>
            <td>{memberOfTheCppcc}</td>
            <td className="txt-right">中共党代表</td>
            <td colSpan="2">{representativesOfTheCpc}</td>
          </tr>
          <tr>
            <td className="txt-right">党委委员</td>
            <td>{memberOfThePartyCommittee}</td>
            <td className="txt-right">部门分类</td>
            <td>{deptClassification}<br />{deptName}</td>
            <td className="txt-right" rowSpan="2">出境证照</td>
            <td colSpan="2">护照<span style={{ marginLeft: '10px' }}>{passport}</span></td>

          </tr>
          <tr>
            <td className="txt-right">干部管理权限</td>
            <td>{authorityOfCadreManagement}</td>
            <td className="txt-right">国家监察对象</td>
            <td>{objectOfSupervision}</td>
            <td colSpan="2">港澳通行证<span style={{ marginLeft: '10px' }}>{eepToHkAndMacau}</span></td>
          </tr>
          <tr>
            <td className="txt-right">现任职务</td>
            <td colSpan="6">{currentDuties}</td>
          </tr>
          <tr>
            <td className="txt-right">身份证号</td>
            <td colSpan="3">{idcard}</td>
            <td className="txt-right">联系方式</td>
            <td colSpan="2">{contact}</td>
          </tr>
          <tr>
            <td className="txt-right">家庭住址</td>
            <td colSpan="6">{address}</td>
          </tr>
          <tr>
            <td className="txt-right">简历</td>
            <td colSpan="6">
              <p style={{ minHeight: '300px' }} dangerouslySetInnerHTML={{ __html: resume }}></p>
            </td>
          </tr>
          <tr>
            <td className="txt-right">家庭主要成员及重要社会关系</td>
            <td colSpan="6">
              <div style={{ minHeight: '300px' }}>
                {
                  relationship.map((item, index) => (
                    <Row key={index}>
                      <Col span={4}>{item.kinsfolk}</Col>
                      <Col span={4}>{item.name}</Col>
                      <Col span={4}>{item.age}</Col>
                      <Col span={4}>{item.politicalOutlook}</Col>
                      <Col span={8}>{item.work}</Col>
                    </Row>
                  ))
                }
              </div>
            </td>
          </tr>
          <tr>
            <td className="txt-right">所在单位</td>
            <td colSpan="6">{placeOfWork}</td>
          </tr>
        </tbody>
      </table>
    </Spin>
  )
}

export default TableMember